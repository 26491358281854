
import gamedata from "./gamedata";
import '../App.css';
import SearchBar from "../components/SearchBar";
//import GameCarousel from "../components/GameCarousel";
import GamesList from "../components/GamesList";
const GamesDB = () => {
   //return the current domain and if it matches bigfoot.pages.dev or bigfootshack.games then return true, else false. Use a react state
  const checkifadsite = window.location.hostname === 'bigfoot.pages.dev' || window.location.hostname === 'bigfootshack.games'|| window.location.hostname === 'www.bigfootshack.games'; 

    if (gamedata.length === 0) {
        return <p>There are no games to show</p>
    }

    return (
        <>
            <div class="game-container">
                <SearchBar /><GamesList gamedata={gamedata} />
                {/*<FilterGames />
                <br />
                <GameCarousel />
                
                <div className="sideAd-gamespage">Ads go here</div>*/}
                
            </div> 
</>
   
    );
}
export default GamesDB;
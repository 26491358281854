import React from 'react';
import { Link } from 'react-router-dom';
/*
function loading() {
    window.location.replace(gamelink);
}
const timeoutId = setTimeout(() => {
    loading();
  }, 2000);
clearTimeout(timeoutId)
*/



const LandingPage = () =>{
    var linkparameters = window.location.search.split('url=')[1];
    var gamelink = "/play/"+linkparameters;
return ( 
    <>
        <div id="loading-div-screen"> 
        <img src="../assets/images/loading-game-new.gif" className="loading-gif"/>
        <h1 className="loading-text">Loading...</h1>
        <Link to={gamelink}><div className="loading-div-button">Play GAME</div></Link>
        </div>
    </>
    
);
}

export default LandingPage;

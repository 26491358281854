import { Link } from "react-router-dom";
const NotFoundPage = () => (
    <>
    <div className="fourofourdiv">
    <h1 className="h1-head-404">404 Page Not Found</h1><br/>
    
    <Link to="/"><div className="button-404"><h1>home</h1></div></Link>
    <Link to="/play"><div className="button-404 floatright"><h1>games</h1></div></Link>
    </div></>    );

export default NotFoundPage
const articledata = [
    {
        name:'article-1',
        title:'    How we started',
        date : '9/10/2023',
        content: [
            'Let me take you back to the winter of 2021,'
        ],
        content2: [
            'It all started the week before winter break, me and my friends decided we would start a games site. We started with a google site, but we soon realized that would not cut it. So Febuary of 2022 I learned HTML & CSS (I already knew JS), and so I started working on v3 of BGS. This was hosted on github with cloudflare pages on the domain bgs.pages.dev. Over the entire summer of 2022 I worked on that site for sometimes hours everyday. Eventually it was finished in time for the 2022-2023 school year. Somehow the bgs.pages.dev link spread around and became super popular. By the time October came around, we were getting 80k+ views a day. This was when I decided to Learn React JS. Over the school year and summer I worked on the site.'
        ],
        content3: [
            'This brings us to where we are today. I am still working on the site, and I am still learning React JS. I hope you enjoy the site, and if you have any questions, feel free to join our discord server and ask or email me at bigfoot.gaming9999@gmail.com.'
        ]
    },
   
];
export default articledata;
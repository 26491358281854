const partners = [
  
    {
      name: "Art Class",
      logoUrl: 'artclass.png',
      description: 'The best all-in-one site for school entertainment',
      link: 'https://artclass.site',
    },

    
    {
      name: "Genarcy",
      logoUrl: 'genarcy.png',
      description: 'Many games to choose from...',
      link: 'https://genarcy.github.io      ',
    },
  
    
    {
      name: "Silvereen Network      ",
      logoUrl: 'silvereen.png',
      description: 'Tons of fun and free games with Moonlight. The cure to boredom at your fingertips. ',
      link: 'https://moonlight.silvereen.net      ',
    },
    {
      name: "Blue Hat Crew",
      logoUrl: 'bluehatcrew.png',
      description: 'Meet the Blue Hat Crew - student tech experts who create solutions for students. We have what you need for easy access to online resources.',
      link: 'https://discord.gg/bhc',
    },
    {
      name: "Totally Science",
      logoUrl: 'totallyscience.png',
      description: 'Your one-stop destination for free online games! Youtube, multiplayer games, Minecraft, and more',
      link: 'https://totallyscience.co/',
    },
      {
        name: 'this could be you!',
        logoUrl: '',
        description: 'Open a ticket in our Discord Server for help',
        link: 'https://forms.gle/zBx32tp442hEdbTX8',
      },
];
 export default partners;
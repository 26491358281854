import React, { useState } from 'react';
import Search from '../icons/Search.png';
import FilterGames from './FilterGames';
import gamedata from '../pages/gamedata';
function SearchBar() {
  const [searchQuery, setSearchQuery] = useState('');

  function handleInputChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/search?q=${searchQuery}`;
  }

  return (
    <>
    <div className='filter-games-page'>
    <form onSubmit={handleSubmit}>

        <input placeholder={"  Search " +gamedata.length+" games"}className="input-search"type="text" value={searchQuery} onChange={handleInputChange} />        <button className='submitsearch' type="submit"><img alt="Search"className='searchimg'src={Search}/> </button>
    </form>
    <FilterGames />
    </div>
  </>
  );
}

export default SearchBar;

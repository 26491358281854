import { Link } from "react-router-dom";
const Game404Page = () => (
    <>
    <div className="fourofourdiv">
    <h1 className="h1-head-404">404 Game Not Found</h1><br/>
    <p2 className="">There was an issue launching this game. Please contact support: <a href="mailto:bigfoot.gaming9999@gmail.com">bigfoot.gaming9999@gmail.com</a></p2><br/><br/><br/>
    <Link to="/"><div className="button-404"><h1>home</h1></div></Link>
    <Link to="/play"><div className="button-404 floatright"><h1>games</h1></div></Link>
    </div></>    );

export default Game404Page
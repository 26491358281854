import React, { useState, useEffect } from 'react';
import Links from '../pages/Links';
const FaviconChecker = ({ Links }) => {
  const [workingLink, setWorkingLink] = useState(null);

  useEffect(() => {
    const checkFavicon = async () => {
      for (const link of Links) {
        try {
          await loadImage(`${link}/favicon.ico`);
          setWorkingLink(link);
          break;
        } catch (error) {
          console.error(`Favicon not found for ${link}. This server is either down or blocked.`);
        }
      }
    };

    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      });
    };

    checkFavicon();
  }, [Links]);

  return (
    <div>
      {workingLink ? (
        <p style={{textAlign:"center",}}>*Some external games might not work.</p>
      ) : (
        <p style={{textAlign:"center",}}>
          Checking Game Status... <br/>*Some external games might not work.
        </p>
      )}
    </div>
  );
};

export default FaviconChecker;

import { Link } from 'react-router-dom';

const BlockpostAd = () =>{

    return (
        <>
   <div class="slope-section-div">
   <div class="blockpost-section">
   <img class="logo2-slope" src="/assets/images/blockpost-large-banner.jpg" alt="Blockpost characters ad"></img>

      <h1 class="slope-headline">BLOCKPOST</h1>
        <div class="slope-section-blur">
        </div>
      <a class="slope-cta-button" target='_Blank' href="https://playblockpost.pages.dev/">PLAY NOW</a>
   </div>
      </div>
      </>
    );
  };
  
  
  export default BlockpostAd;
import { useEffect, useState } from "react"
import { getAuth } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
const useUser = () => {
const [user, setUser] = useState(null);
const [isLoading, setIsLoading] = useState(true);
useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
            setUser(user);
            setIsLoading(false);
    });
    return unsubscribe;
},[])
    return {user, isLoading};
}

export default useUser;
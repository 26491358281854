import React, { useEffect, useState } from 'react';
import gamedata from '../pages/gamedata.js';
import { encode as base64_encode } from 'base-64';
import { Link } from 'react-router-dom';
import Trending from '../Trending.png';
import { useLocation } from 'react-router-dom';
import { MdOutlineFiberNew } from "react-icons/md";
import Back from '../icons/Back.png';
import AdsterraAds from './AdsterraAds'; // Import your AdsterraAds component
import BlockpostAd from './BlockpostAd.js';
import SlopeAd from './SlopeAd.js';

function GamesList() {
  const [sortedGames, setSortedGames] = useState([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const gamesPerPage = 40; // You can adjust the number of games per page
  const [showImages, setShowImages] = useState(false);

  const handleToggle = () => {
    setShowImages(!showImages);
  }

  let filteredGames = gamedata;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    let filteredGames = gamedata;

    const category = searchParams.get('type');
    if (category) {
      filteredGames = filteredGames.filter((game) => game.gameType === category);
    }

    const category1 = searchParams.get('tag');
    if (category1) {
      filteredGames = filteredGames.filter((game) => game.isTrending === category1);
    }

    const category2 = searchParams.get('date');
    if (category2) {
      filteredGames = filteredGames.filter((game) => game.dateAdded >= category2);
    }

    // Sort the filtered games by trending status, with trending games on top
    filteredGames.sort((a, b) => {
      if (a.isTrending && !b.isTrending) {
        return -1;
      } else if (!a.isTrending && b.isTrending) {
        return 1;
      } else {
        return 0;
      }
    });

    setSortedGames(filteredGames);
  }, [gamedata, location]);

  // Calculate total number of pages
  const totalPages = Math.ceil(sortedGames.length / gamesPerPage);

  // Calculate index of the last game on the current page
  const indexOfLastGame = currentPage * gamesPerPage;
  // Calculate index of the first game on the current page
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  // Get the games for the current page
  const currentGames = sortedGames.slice(indexOfFirstGame, indexOfLastGame);

  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
  const dateString = sixtyDaysAgo.toISOString().slice(0, 10); // Define dateString here

  return (
    <>
      <a href='#topofgames'><img src={Back} alt='Up' title="Go back to the top of the page" className='back-to-top-icon-games-page' /></a>
      <div className='togglethumb-div'>
        <h2>{showImages ? "Hide" : "Show"} thumbnails</h2>
        <div id='topofgames' className="toggle-container">
          <input type="checkbox" id="toggle" checked={showImages} onChange={handleToggle} />
          <label htmlFor="toggle" title={showImages ? "Hide Thumbnails" : "Show Thumbnails"}></label>
        </div>
      </div>

      <div className="games-container1">
        {currentGames.map((game, index) => (
          <React.Fragment key={game.name}>
            <Link
              className="game-card-link"
              to={"/loading/?url=" + base64_encode(`${game.path}`) + `?imgId=${game.img}&gameName=${game.name}&isTrending=${game.isTrending}&type=${game.gameType}`}
            >
              <div
                className={showImages ? "game-card" : "game-card-toggled"}
                loading="lazy"
                style={showImages ? {
                  backgroundImage: `url("/assets/images/${game.img}")`,
                } :
                  {
                    backgroundImage: 'none',
                  }
                }
              >
                <div className={showImages ? "game-card-content" : "game-card-content-toggled"}>
                  <h3 className="game-card-title">{game.name}</h3>
                  <p className="game-card-description">
                    {game.description === '' && 'No descripton yet'}
                    {game.description && game.description.substring(0, 55) + '...'}
                  </p>
                  {game.isTrending && (
                    <img alt="Trending Icon" className="game-card-logo-trending" src={Trending} />
                  )}
                  <p className="">
                    {game.dateAdded >= dateString &&
                      <MdOutlineFiberNew className="filter-icon-game" />
                    }
                  </p>
                </div>
              </div>
            </Link>
          </React.Fragment>
        ))}
      </div>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={currentPage === i + 1 ? 'active-pagenation pagenation' : 'pagenation-notactive pagenation'}
          >
            {i + 1}
          </button>
        ))}
      </div>
      {/*       <AdsterraAds className="banner-ad-adsterra-div"/>
 */}
<BlockpostAd />
<SlopeAd />
    </>
  );
}

export default GamesList;

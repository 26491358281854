import React, { useState } from 'react';
import { FaBell } from 'react-icons/fa';

const Announcements = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="announcements">
      <button className="announcements-btn" onClick={toggleMenu}>
        <FaBell />
      </button>
      <div className={isOpen ? "announcements-menu active" : "announcements-menu"}>
        <button className="close-btn" onClick={toggleMenu}>&times;</button>
        <div className="announcement-list">
        <h2>Announcements</h2>
          <div className="announcement-item">
            <div className="announcement-title">Share with your friends!</div>
            <div className="announcement-description">Lets regrow Bigfoot's Game Shack. If you need more links, email bigfoot.gaming9999@gmail.com</div>
            <div className="announcement-date">October 9, 2024</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcements;

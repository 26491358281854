import React, { useState } from 'react';
import Back from '../icons/Back.png'
import { Link } from 'react-router-dom';
import Fullscreen from '../icons/Fullscreen.svg'
import { useParams } from "react-router-dom";
import {decode as base64_decode} from 'base-64';
import { useEffect } from 'react';
import Links from '../pages/Links';
import Random from '../icons/Random.png'
import { encode as base64_encode } from "base-64";
import gamedata from '../pages/gamedata';

const GameBar = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [isCloaked, setIsCloaked] = useState(true);
  const [workingLink, setWorkingLink] = useState(null);
  useEffect(() => {
    const checkFavicon = async () => {
      for (const link of Links) {
        try {
          await loadImage(`${link}/favicon.ico`);
          setWorkingLink(link);
          break;
        } catch (error) {
          console.error(`Favicon not found for ${link}. This server is either down or blocked.`);
        }
      }
    };

    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      });
    };

    checkFavicon();
  }, [Links]);
  const {swfId} = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const gameName = queryParams.get("gameName");

  const type = queryParams.get("type");

  const handleToggle = () => {
    setIsHidden(!isHidden);
  };

  function gcloak() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = 'https://www.pngall.com/wp-content/uploads/9/Google-Drive-Logo-Transparent-180x180.png';
    document.title = 'My Drive - Google Drive';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  function bgscloak() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/assets/images/logonew-dark.png';
    document.title = "Bigfoot's Game Shack";
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  
  const handleCloak = () => {
    setIsCloaked(!isCloaked);
    if (isCloaked == true) {
      gcloak();
    }
    if (isCloaked == false) {
      bgscloak();
    }
  };
  var src;
  if (type === "flash") {
    let decoded = base64_decode(swfId);
    src = "https://"+window.location.host+'/assets/flash/index.html?swf=' + decoded;
  }
  if (type === "html5") {
    let decoded = base64_decode(swfId);
    src = "https://"+window.location.host+`/assets/html5/` + decoded;
  }
  if (type === "external") {
    let decoded = base64_decode(swfId);
    //src = workingLink+"/service/"+swfId;
    src = decoded;

    }
  if (type === "waflash") {
    let decoded = base64_decode(swfId);
    src = workingLink+"/service/"+ 'aHR0cHM6Ly9iZ3MucGFnZXMuZGV2L2cvZ2ZpbGVzL2dmaWxlcy9mbGFzaC8=' + swfId;
  }
function openIframeWindow() {
  const url = 'about:blank';
  const name = 'newWindow';
  const features = 'fullscreen=yes,titlebar=yes,scrollbars=yes,width=1080,height=920';
  const newWindow = window.open(url, name, features);
  if (newWindow) {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.style.width = 'calc(100% + 16px)';
    iframe.style.height = 'calc(100% + 16px)';
    iframe.style.border = 'none';
    iframe.style.margin = '-8px';
    iframe.allowFullscreen = true;
    iframe.requestFullscreen();
    newWindow.document.body.appendChild(iframe);
  }
}


window.src = src;


//<img className={isCloaked ? "krunkdocvisible":"krunkdochidden" } src='/assets/images/krunkdoc.png'></img>


function redirectToRandomGame() {
  const randomGame = gamedata[Math.floor(Math.random() * gamedata.length)];
  window.location.href =   base64_encode(`${randomGame.path}`) + `?imgId=${randomGame.img}&gameName=${randomGame.name}&isTrending=${randomGame.isTrending}&type=${randomGame.gameType}`;

}
  return (
    <>
    <div className='game-bar'
      style={{
        position: 'absloute',
        bottom: isHidden ? '-70px' : '0px',
        color: '#fff',
        right: '0px',
        padding: '10px',
        textAlign: 'center',
        transition: 'bottom 0.3s ease-in-out',

  
      }}
    >
                <Link title="Go Back"to="/play"><img src={Back} alt="Back" className="backwards-button"/>                </Link>
                <img src={Fullscreen} alt="Fullscreen" title="Open in about:blank"onClick={openIframeWindow} className="backwards-button fullscreen"/>
                <h2 className='h2-game-bar'>Bigfoot's Game Shack</h2>
                <img src={isCloaked ? "/assets/images/icons/Eyeclose.png": "/assets/images/icons/Eyeopen.png"} alt="Screen Cloak Button" onClick={handleCloak} className="backwards-button fullscreen" title={isCloaked ? "Tab is currently set to default. Click to hide the tab":"Tab is currently hidden. Click to revert tab settings"}/>
                <img src={Random} alt="Screen Cloak Button" onClick={redirectToRandomGame} className="backwards-button fullscreen" title={"Click to play a random game"}/>


    </div></>
  );
};

export default GameBar;
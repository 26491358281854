import { Link } from 'react-router-dom';

const SlopeAd = () =>{

    return (
        <>
   <div class="slope-section-div">
   <div class="slope-section">
   <img class="logo2-slope" src="/assets/images/logonew-dark.png" alt="BGSLOGO"></img>

      <h1 class="slope-headline">SLOPE GAME</h1>
        <div class="slope-section-blur">
        </div>
      <a class="slope-cta-button" href="/play/c2cv?imgId=img1.jpg&amp;gameName=Slope%20Game&amp;isTrending=isTrending&amp;type=html5">PLAY NOW</a>
   </div>
      </div>
      </>
    );
  };
  
  
  export default SlopeAd;
import React from 'react';
import { MdOutlineFiberNew } from "react-icons/md";
const AnnouncementsPage = () => {
  const announcements = [
    {
      title: 'Still adding servers',
      content: 'Our servers may be blocked. In the mean time, play flash or HTML5 games',
      date: '2023-05-20',
    },
    {
      title: 'Flash Games Fixed',
      content: 'All flash games now load on the website. You can still choose to open it in about:blank',
      date: '2023-05-20',
    }, {
      title: 'All URLs updated to v4',
      content: 'If you expeirence any issues with our site, email me at bigfoot.gaming9999@gmail.com',
      date: '2023-05-20',
    },
    
    // Add more announcements here
  ];

  // Sort announcements by date (newest to oldest)
  announcements.sort((a, b) => new Date(b.date) - new Date(a.date));
  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
  const dateString = sixtyDaysAgo.toISOString().slice(0, 10);
  return (
    <>
    <h1 className='announcementsh1'>Announcements</h1>
    <div className='announcementsdiv'>
      {announcements.map((announcement) =>  (
        <div className='announcementsdiv2' >
          
          <h2 className='announcementsdiv2-head'>{announcement.title}</h2>
          <h3 className='announcementsdiv2-date'>{announcement.date}</h3>
          <p className='announcementsdiv2-content'>{announcement.content}</p>
          {announcement.date >= dateString ?
          <MdOutlineFiberNew className='new-announcements'/> : null
          }
        </div>
      ))}
    </div>
    </>
  );
};

export default AnnouncementsPage;

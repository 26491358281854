import React, { useState, useEffect } from 'react';

const MinecraftText = () => {
  // Array of strings
  const textArray = [
    "Infinite Possibilities",
    "Bigfoot's Game Shack",
    "Is Bigfoot real?",
    "Yay, games!",
    "Are we living in a simulation?",
    "Exciting!",
    "What would win: Bean Tower or Fruit Tower?",
    "What are the chances?",
    "Bigfoot must be the best at hide and seek",
    "Thanks for using Bigfoots Game Shack!",
    "Also check out Interstellar",
    "Warm and Cold.",
    "share with your friends!",
    "Never gonna give you up, never gonna let you down...",
    "lime.",
    "Can you do my math homework?",
    "The manager gets paid less...",
    "Time is irrelevant like everything else except this website...",
    "Did you know the three dots are called elipses...",
    "Grapes light up in flames in the microwave...",
    "Thousands of colors!",
    "Thanks for 5 Million Visitors!",
    "Random splash!",
    "Is this from minecraft?",
    "12345 is a bad password!",
    "Is Bigfoot even real?",
    "Im not dead...",
    "Made in the USA",
    "Best in the world!",
    "Check out our socials!",
    "Bigfoot says hi!",
];

  // State to store the randomly chosen text
  const [randomText, setRandomText] = useState('');

  // Function to choose a random text from the array
  const chooseRandomText = () => {
    const randomIndex = Math.floor(Math.random() * textArray.length);
    const selectedText = textArray[randomIndex];
    setRandomText(selectedText);
  };
  function chooseRandomText1() {
    const randomIndex = Math.floor(Math.random() * textArray.length);
    const selectedText = textArray[randomIndex];
    setRandomText(selectedText);
  };

  // useEffect to generate random text on component load
  useEffect(() => {
    chooseRandomText();
  }, []);

  return (
    <>
      {randomText && <a onClick={chooseRandomText1}><p className="minecraft-text-main-page">{randomText}</p></a>}
      </>
  );
};

export default MinecraftText;


import { useEffect, useState } from 'react';
import appdata from '../pages/appdata.js';
import { encode as base64_encode } from 'base-64';
import { Link } from 'react-router-dom';
import Logonew from '../Logonew.png';
import Trending from '../Trending.png';
import { useLocation } from 'react-router-dom';
import { MdOutlineFiberNew } from "react-icons/md";

function AppList() {
  const [sortedapps, setSortedapps] = useState([]);
  const location = useLocation();
  const [isappHidden, setisappHidden] = useState(false);
  const [showImages, setShowImages] = useState(false);

  const handleToggle = () => {
    setShowImages(!showImages);
  }
  const handleToggleapp = () => {
    setisappHidden(!isappHidden);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    let filteredapps = appdata;

    const category = searchParams.get('type');
    if (category) {
      filteredapps = filteredapps.filter((app) => app.appType === category);
    }

    const category1 = searchParams.get('tag');
    if (category1) {
      filteredapps = filteredapps.filter((app) => app.isTrending === category1);
    }

    const category2 = searchParams.get('date');
    if (category2) {
      filteredapps = filteredapps.filter((app) => app.dateAdded >= category2);
    }

    setSortedapps(filteredapps);
  }, [appdata, location]);
  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
  const dateString = sixtyDaysAgo.toISOString().slice(0, 10);


  return (
    <>
        <div className='togglethumb-div'>
      <h2>{showImages ? "hide" : "show"} thumbnails</h2>
      <div className="toggle-container">
        <input type="checkbox" id="toggle" checked={showImages} onChange={handleToggle} />
        <label htmlFor="toggle"title={showImages ? "Hide Thumbnails":"Show Thumbnails"}></label>
      </div>
    </div>
    <p2 className="center-text">The apps page is still under construction. </p2>
      <div className="games-container1">
        {sortedapps.map((app) => (
          <Link
            onClick={handleToggleapp}
            key={app.name}
            className="game-card-link"
            to={base64_encode(`${app.path}`) + `?imgId=${app.img}&gameName=${app.name}&isTrending=${app.isTrending}&type=${app.appType}`}
          >
            <div
              className={showImages ? "game-card" : "game-card-toggled"}
              style={showImages ? {
                backgroundImage: `url("/assets/images/${app.img}")`,
              }
            :
            {
              backgroundImage: 'none',
            }
          }
            >
              <img loading="lazy" alt="BGS logo" className={showImages ? "game-card-logo" :"game-card-logo-hidden"} src={Logonew} />

              <div className={showImages ? "game-card-content" : "game-card-content-toggled"}>
                <h3 className="game-card-title">{app.name}</h3>
                <p className="game-card-description">
                  {app.description === '' && 'No descripton yet'}
                  {app.description && app.description.substring(0, 55) + '...'}
                </p>
                {app.isTrending && (
                  <img alt="Trending Icon" className="game-card-logo-trending" src={Trending} />
                )}
                <p className="">
                {app.dateAdded >= dateString &&
                <MdOutlineFiberNew className="filter-icon-game" />
                }
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}

export default AppList;

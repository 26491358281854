import React from 'react';
import gamedata from '../pages/gamedata';
import { Link } from 'react-router-dom';
const IframeDetector = () => {
  const isIframed = window.top !== window.self;
  const currentURL = window.location;
  const funnyMessages = [
    "I'm sorry, Dave. I'm afraid I can't let you steal my content.",
    "No iframe, no dice.",
    "If you can't beat 'em, iframe 'em... or not.",
    "You shall not iframe!",
    "Sorry, this content is not for sharing. Keep scrolling.",
    "Unauthorized iframing detected. Goodbye!",
    "No ifs, ands, or iframes about it.",
    "Iframes are so 2010.",
    "If you're reading this, you're not allowed to iframe.",
    "Go ahead, try to iframe me. I dare you.",
    "You shall not pass! Unless you're not iframing, in which case, welcome.",
    "Warning: Iframing may cause dizziness, nausea, and legal action.",
    "Sorry, my content is not available for framing. Please enjoy elsewhere.",
    "No iframe, no harm, no foul.",
    "Unauthorized iframe attempt detected. Alerting the authorities.",
    "This iframe is a no-go. Sorry, not sorry.",
    "This content is protected by anti-iframe technology. Nice try, though.",
    "If you can't play nice, no iframe for you!",
    "Iframe me once, shame on you. Iframe me twice, shame on me.",
    "Iframes are like wearing socks with sandals. Just don't.",
    "The iframe stops here.",
    "No iframe? No problem!",
    "If you iframe me, I'll iframe you back!",
    "The iframe is a lie.",
    "If you iframe me, I'll call your mother.",
    "Iframes? Ain't nobody got time for that.",
    "Iframes are a pain in the... you know what.",
    "Unauthorized iframe detected. Initiating self-destruct sequence. Just kidding.",
    "No iframe, no worries.",
    "If you iframe me, I'll send you to my spam folder.",
    "Iframes? Not in my house.",
    "Unauthorized iframe attempt detected. Have a nice day!",
    "This iframe is off-limits. Sorry not sorry.",
    "Iframes are like bad haircuts. Just don't.",
    "You shall not iframe. Period.",
    "If you iframe me, I'll sick my dog on you. He's very protective of his content.",
    "Unauthorized iframe attempt detected. Don't make me call my lawyer.",
    "No iframe? No problem! Just enjoy my content the old-fashioned way.",
    "Iframes? More like i-don't-want-them.",
    "This content is not for sharing. Keep it to yourself.",
    "Unauthorized iframe detected. Please exit the iframe and try again.",
    "Iframes are like a box of chocolates. You never know what you're going to get.",
    "Iframes? More like i-don't-care.",
    "This iframe is a no-fly zone.",
    "No iframe, no drama.",
    "If you iframe me, I'll give you a piece of my mind. And it's not a small piece.",
    "Unauthorized iframe attempt detected. Nice try, though.",
    "This content is not available for framing. Sorry, not sorry.",
    "Iframes are like a bad dream. You just want to wake up and make them go away.",
    "No iframe, no problem. Enjoy my content on my website instead!",
    "If you iframe me, I'll... wait, why would you even want to iframe me?",  ]  
// Generate a random index within the range of the array
const randomIndex = Math.floor(Math.random() * funnyMessages.length);

// Return the element at the random index
const randomMessage = funnyMessages[randomIndex];

  if (isIframed && window.location.host !== '' && window.location.host !== "www.gstatic.com") {

    return <div id='stopStealingMyGames'><h1 className='stealh1'>Bigfoot's<br /> Game <br />Shack</h1><br /><h1 className='rando'>{randomMessage}</h1><button><Link target="_blank" rel="noreferrer" to={currentURL}>Click here to play over {gamedata.length} games</Link></button></div>;
  } else {
    return null;
}
};

export default IframeDetector;


import { NavLink, Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import useUser from './hooks/useUser';
import logonew from './Logonew.png'
import { useState } from 'react';
import Logout from './icons/Logout.png';
import Login from './icons/Login.png';
import Discord from './icons/Discord.svg';
import Youtube from './icons/Youtube.svg';
import Search from './icons/Search.png';
import gamedata from './pages/gamedata.js';
import Arrow from './icons/Arrow.png';
function Navbar() {
  // Initialize the active link state to the first link
  const [isHidden, setIsHidden] = useState(false);
  const [isHidden1, setIsHidden1] = useState(false);

  const handleNavToggle = () => {
    setIsHidden1(!isHidden1);
  };
//        <a title="Sub to us on YT" href="https://www.youtube.com/@bigfoots-game-shack"><img alt="navimg"className={isHidden1 ?  "icon-nav-extended hidden" : "icon-nav"}src={Youtube} /><h1 className={isHidden1 ? "h1-nav":"hidden"}>Youtube</h1></a><br/>
//  <img src={Arrow} alt="arrow" className={isHidden1 ?  "arrow-nav-clicked" : "arrow-nav"} onClick={handleNavToggle} />
/*<form onSubmit={handleSubmit}>
          <button title={isHidden ?  'Close Search Bar' : `Search up ${gamedata.length} games`} className={isHidden1 ? "submitsearch-nav-extended hidden":"submitsearch"} type="button"><img alt="Search"   className={isHidden1 ? "searchimg-nav-extended":"searchimg-nav icon-nav"} src={Search} onClick={handleToggle} /><h1 className={isHidden1 ? "h1-nav":"hidden"}>Search</h1> </button>
          {isHidden && 
           <input
           
           style={{
            color: '#fff',
            left: isHidden ? '55px' : '-740px',
            paddingLeft: '10px',
            marginLeft: '50px',
            transition: 'left 1s ease-in-out',
    
      
          }}
          className="input-search-nav" type="text" value={searchQuery} onChange={handleInputChange} /> 
          }
      </form>
       {user 

        ? <button  className="button-nav-user"onClick={() =>{signOut(getAuth());}}>
            <img alt="logiout"className="icon-nav"src={Logout} /></button>

        : <button className="button-nav-user" onClick={() => {
            navigate('/login');
        }}><img alt="login"className="icon-nav"src={Login} /></button>
        }
      
      */
  const {user} = useUser();
  const navigate = useNavigate();
  // Define the navbar links
      //{ "label": 'Apps', "url": '/apps', "icon": "/assets/images/icons/App.svg", "title":"Apps"},

  const links = [
    { "label": 'Home', "url": '/', "icon": "/assets/images/icons/Home.svg", "title":"Home" },
  //{ "label": 'Announcements', "url": '/announcements', "icon": "/assets/images/icons/info.svg", "title":"Announcements"},
    { "label": 'Games', "url": '/play', "icon": "/assets/images/icons/Games.svg", "title":"Games"},
  ];

 //    { "label": 'Articles', "url": '/articles', "icon": "/assets/images/icons/Articles.png", "title":"Articles" }

    const [searchQuery, setSearchQuery] = useState('');
  
    function handleInputChange(event) {
      setSearchQuery(event.target.value);
    }
  
    function handleSubmit(event) {
      event.preventDefault();
      window.location.href = `/search?q=${searchQuery}`;
    }
  
    const handleToggle = () => {
      setIsHidden(!isHidden);
    };
  
  
    const isIframed = window.top !== window.self;
    if (isIframed) {
      return null;
    }
//<img alt="navimg"className={isHidden1 ?  "logo-nav-extended" : "logo-nav"} src="/assets/images/logonew-dark.png"/>

  return (
<nav className={isHidden1 ?  "nav-extended" : "navbar"}>
      <h1 className='navtexttitle'>Bigfoot's Game Shack</h1>

      {isHidden1 ?
        
        links.map((link) => (
          <Link exact={true} title={link.title} to={link.url}><div className='nav-div'>{/*<img alt="navimg"  className={isHidden1 ?  "icon-nav-extended" : "icon-nav"} src={link.icon} />*/}<h1 className='h1-nav'>{link.title}</h1></div><br/></Link>
          
)) : links.map((link) => (
                   <Link exact={true} title={link.title} to={link.url}><img alt="navimg"className={isHidden1 ?  "icon-nav-extended" : "icon-nav"} src={link.icon} /></Link>
                   
        ))  
        
}


      

  
        
    </nav>
  );
}
export default Navbar;
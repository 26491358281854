import { useParams } from "react-router-dom";
import '../App.css';
import NotFound from "./Notfound";
import articledata from "./articledata";
import SlopeAd from "../components/SlopeAd";
import Back from '../icons/Back.png'

const ArticlesNew = () => {
    const {articleId} = useParams();

        
        
        const article = articledata.find(articledata => articledata.name === articleId);

       

    if (!article){
        return <NotFound />
    }

    return (
        <>
                <SlopeAd />
        <div class="article-content-text">
                            <img src={Back} alt="Back" className="backbutton" onClick={() => window.history.back()}></img>
<br/>
        <img src='/assets/images/builtforChromebookgamerstransparent.png' alt="Built for Chromebook Gamers" className="bfcgbigfoottext"></img>

        <h1>{article.title}</h1>

        {article.content.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
            {article.content2.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
            {article.content3.map(paragraph =>(
            <p class="article-conent">{paragraph}</p>
        ))}
        </div>
        

</>
   
    );
}
export default ArticlesNew;
import { useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import "../App.css";
import NotFoundPage from "./404Page";
import gamedata from "./gamedata";
import { decode as base64_decode } from "base-64";
import GameBar from "../components/GameBar";
import FaviconChecker from "../components/FaviconChecker";
import Links from "./Links";
import SearchBarbare from "../components/SearchBarBare";
import Game404Page from "./Game404Page";
import SlopeAd from "../components/SlopeAd";
import GameSlider from "../components/GameSlider";
import AdsterraAds from "../components/AdsterraAds";
import BlockpostAd from "../components/BlockpostAd";

const Games = () => {
  const { swfId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const imgId = queryParams.get("imgId");
  const gameName = queryParams.get("gameName");
  const isTrending = queryParams.get("isTrending");
  const type = queryParams.get("type");

  const decodedSwf = base64_decode(swfId);
  const game = gamedata.find((gamedata) => gamedata.path === decodedSwf); // SWF ID IS THE PATH
  const [checked, setChecked] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const handleFrameToggle = () => {
    setIsHidden(!isHidden);
  };
  // Set up state for FPS counter
  const [fps, setFps] = useState(0);
  const fpsRef = useRef(0);
  const lastTimeRef = useRef(null);

  const [workingLink, setWorkingLink] = useState(null);
  useEffect(() => {
    // Save the current meta title and description
    const currentTitle = document.title;
    const currentDescription = document.querySelector('meta[name="description"]').getAttribute('content');

    // Change the meta title and description
    if (document.title == "My Drive - Google Drive") {
      console.log("Applying Tab Cloak to next page")
    }
    else {
    document.title = gameName+" | Bigfoot's Game Shack";
    }
    document.querySelector('meta[name="description"]').setAttribute('content', "Play "+gameName+" on Bigfoot's Game Shack | 500+ Games made for Chromebook gamers | Bigfoot's Game Shack v4");
     
    // Revert the meta title and description when the component is unmounted
    return () => {
      if (document.title == "My Drive - Google Drive") {
        console.log("Applying Tab Cloak to next page")
      }
      else {
      document.title = "Bigfoot's Game Shack";
      }
      document.querySelector('meta[name="description"]').setAttribute('content', currentDescription);
    };
  }, []);
  useEffect(() => {
    const checkFavicon = async () => {
      for (const link of Links) {
        try {
          await loadImage(`${link}/favicon.ico`);
          setWorkingLink(link);
          break;
        } catch (error) {
          console.error(
            `Favicon not found for ${link}. This server is either down or blocked.`
          );
        }
      }
    };
   
    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = url;
      });
    };

    checkFavicon();
  }, [Links]);

  // Calculate FPS
  const calculateFps = (currentTime) => {
    if (lastTimeRef.current !== null) {
      const diff = currentTime - lastTimeRef.current;
      const currentFps = Math.round(1000 / diff);
      if (currentFps <= 300) {
        fpsRef.current = currentFps;
        setFps(currentFps);
      }
    }
    lastTimeRef.current = currentTime;
    requestAnimationFrame(calculateFps);
  };

  // Start calculating FPS
  requestAnimationFrame(calculateFps);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  if (!game) {
    return <NotFoundPage />;
  }
  var src;

  if (type === "flash") {
    let decoded = base64_decode(swfId);
    src = "/assets/flash/?swf=" + decoded;
  }
  if (type === "html5") {
    let decoded = base64_decode(swfId);
    src = `/assets/html5/` + decoded;
  }
  if (type === "external") {
    let decoded = base64_decode(swfId);
    //src = workingLink+"/service/"+swfId;
    src = decoded;
  }
  if (type === "waflash") {
    let decoded = base64_decode(swfId);
    //src = workingLink+"/service/"+ 'aHR0cHM6Ly9iZ3MucGFnZXMuZGV2L2cvZ2ZpbGVzL2dmaWxlcy9mbGFzaC8=' + swfId;
    src = "/assets/flash/" + decoded;
  }

  function openIframeWindow() {
    const url = "about:blank";
    const name = "newWindow";
    const features =
      "fullscreen=yes,titlebar=yes,scrollbars=yes,width=1080,height=920";
    const newWindow = window.open(url, name, features);
    if (newWindow) {
      const iframe = document.createElement("iframe");
      iframe.src = src;
      iframe.style.width = "calc(100% + 16px)";
      iframe.style.height = "calc(100% + 16px)";
      iframe.style.border = "none";
      iframe.style.margin = "-8px";
      iframe.allowFullscreen = true;
      iframe.requestFullscreen();
      newWindow.document.body.appendChild(iframe);
    }
  }
  if (
    type !== "waflash" &&
    type !== "flash" &&
    type !== "html5" &&
    type !== "external"
  ) {
    //return a page that says they can't find the game "type"
    return <Game404Page />;
  }
/////////////////////////////////////////////////////////////////////////////

  /*  <div>
          <img className="logo-gamespage-des" src="../assets/images/logonew-dark.png"></img>
      </div>*/

  return (
    <>
     

     <GameSlider style={{
      marginLeft: '15px',
     }}/>

      <div className="game-content">

        <div>
          <div className="iframewithbar">
            <div
              id="iframe-block-external"
              width={500}
              height={500}
              className="iframe-block-external"
              style={{
                display: isHidden ? "none" : "inherit",
              }}
            >
              <div
                class="img-game-frame-back"
                style={{
                  backgroundImage: `url("/assets/images/${imgId}")`,
                }}
              ></div>
              <div className="div-game-z">
                <img
                  class="img-frame-game"
                  src="/assets/images/logonew-dark.png"
                ></img>
                <img
                  className="img-in-game"
                  src={"/assets/images/" + imgId}
                ></img>

                {type === "external" ? <FaviconChecker Links={Links} /> : null}
                {type === "external" ? (
                  <button
                    className="button-game-frame"
                    onClick={openIframeWindow}
                  >
                    Click to launch {gameName}
                  </button>
                ) : (
                  <button
                    className="button-game-frame"
                    onClick={handleFrameToggle}
                  >
                    Click to launch {gameName}{" "}
                  </button>
                )}
              </div>
            </div>

            <iframe
              id="iframe-game"
              title="gameloader"
              width={500}
              height={500}
              className="iframe-game"
              src={
                type === "html5" || type === "flash" || type === "waflash"
                  ? src
                  : ""
              }
              style={{
                display: isHidden ? "block" : "none",
              }}
            />
            <GameBar />
            
          </div>
        </div>
        <SlopeAd />
        <BlockpostAd />

        <div className="grid-game-topstuff">
        <div className="fpscounter">
          <input
            type="checkbox"
            id="toggle-fps"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          <label
            title={checked ? "Hide FPS counter" : "Show FPS counter"}
            style={{
              background: checked ? "rgb(2, 2, 2)" : "rgb(2, 2, 2)",
              flexDirection: checked ? "row" : "row-reverse",
            }}
            for="toggle-fps"
            class="toggle-label"
          ></label>
          <p
            style={{
              display: checked ? "block" : "block",
              width: checked ? "315px" : "revert",
            }}
          >
            {checked ? "Current FPS:" + fpsRef.current : "FPS"}
          </p>
          {/* Display FPS counter */}
        </div>
        <div className="search-game-page-bar">
          <SearchBarbare />
        </div>
        
        

        <div className="game-info-top">
        
      <div>
        <h1 className="game-h1">
          {gameName}
        </h1>
        
        </div>
      
        <div>
        <img className="img-game-icon" src={"/assets/images/" + imgId}></img>
        </div>
        </div>
        </div>

      </div>



      <div className="game-content-mobile">

        <div>
          <div className="iframewithbar">
            <div
              id="iframe-block-external"
              width={500}
              height={500}
              className="iframe-block-external"
              style={{
                display: isHidden ? "none" : "inherit",
              }}
            >
              <div
                class="img-game-frame-back"
                style={{
                  backgroundImage: `url("/assets/images/${imgId}")`,
                }}
              ></div>
              <div className="div-game-z">
                <img
                  class="img-frame-game"
                  src="/assets/images/logonew-dark.png"
                ></img>
                <img
                  className="img-in-game"
                  src={"/assets/images/" + imgId}
                ></img>

                {type === "external" ? <FaviconChecker Links={Links} /> : null}
                {type === "external" ? (
                  <button
                    className="button-game-frame"
                    onClick={openIframeWindow}
                  >
                    Click to launch {gameName}
                  </button>
                ) : (
                  <button
                    className="button-game-frame"
                    onClick={handleFrameToggle}
                  >
                    Click to launch {gameName}{" "}
                  </button>
                )}
              </div>
            </div>

            <iframe
              id="iframe-game"
              title="gameloader"
              width={500}
              height={500}
              className="iframe-game"
              src={
                type === "html5" || type === "flash" || type === "waflash"
                  ? src
                  : ""
              }
              style={{
                display: isHidden ? "block" : "none",
              }}
            />
            <GameBar />
            
          </div>
        </div>
        
      </div>

<div class="div-game-z-mobile">   <img
                  class="img-frame-game"
                  src="/assets/images/logonew-dark.png"
                ></img>
                <img
                  className="img-in-game"
                  src={"/assets/images/" + imgId}
                ></img>
                <h1  className="slope-headline center-text">Our games are not available on mobile! Please use a computer.</h1>
                <p class="slope-subheadline">Please use a computer to play this game</p>
                </div>

    </>
  );
};

export default Games;

// GameSlider.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const games = [
  { id: 1, title: 'Slope Game', link: '/play/c2cv?imgId=img1.jpg&gameName=Slope%20Game&isTrending=isTrending&type=html5', imageUrl: '/assets/images/slopead.png' },
  { id: 2, title: 'Blockpost.io', link: '/play/aHR0cHM6Ly9pa2F0Y2hlbG8uZ2l0aHViLmlvL2Jsb2NrcG9zdC8=?imgId=img45.png&gameName=Blockpost.io&isTrending=false&type=external', imageUrl: '/assets/images/blockpostpromo-mobile.jpg' },
  { id: 7, title: "Papa's Pizzeria", link: '/play/UGFwYXNQaXp6ZXJpYS5zd2Y=?imgId=papas-pizza&gameName=Papa%27s%20Pizzeria&isTrending=true&type=flash', imageUrl: '/assets/images/papas-pizza-thumb.webp' },
  { id: 3, title: 'IDLE Breakout', link: '/play/aWRsZS1icmVha291dA==?imgId=idle.png&gameName=Idle%20Breakout&isTrending=false&type=html5', imageUrl: '/assets/images/idle-breakout-png.png' },
  { id: 4, title: 'Retro Bowl', link: 'aHR0cHM6Ly9tYXRoLmJpZ2Zvb3RzaGFjay5uZXQv?imgId=retro-bowl.jpg&gameName=Retro%20Bowl&isTrending=true&type=external', imageUrl: '/assets/images/retrobowl-landscape.png' },
  { id: 5, title: 'Run 2', link: '/play/cnVuMi5zd2Y=?imgId=img181.png&gameName=Run2&isTrending=false&type=flash', imageUrl: '/assets/images/run-3-thumb-landscape.png' },
  { id: 6, title: 'OVO', link: '/play/b3ZvLw==?imgId=img30.png&gameName=OvO&isTrending=false&type=html5', imageUrl: '/assets/images/ovo-thumb.png' },
 // { id: 8, title: 'JackSmith', link: '/play/amFja3NtaXRoLnN3Zg==?imgId=img175.png&gameName=Jacksmith&isTrending=isTrending&type=flash', imageUrl: '/assets/images/jacksmith-thumb.png' },

//jacksmith-thumb
  
];

const GameSlider = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="game-slider-container">
      <Slider {...settings}>
        {games.map((game) => (
            <a href={game.link}>
          <div key={game.id} className="game-slide">
            <img src={game.imageUrl} alt={game.title} />
            <div className="game-title">{game.title}</div>
          </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default GameSlider;

import React from 'react';
import '../styles/partner.css';
import partners from './partnerdata.js';
const PartnerCard = ({ partner }) => {
  return (
    <div className="partner-card">
      <img src={partner.logoUrl === "" ?
       "/assets/images/logonew.png" : "/assets/images/partners/"+partner.logoUrl
       } alt={""} />
      <h3>{partner.name}</h3>
      <p>{partner.description}</p>
      <a href={partner.link}>Visit website</a>
    </div>
  );
};

const PartnersPage = () => {
 

  return (
    <div className="partners-page">
      <h1 className='partner-heading'>Our Partners and Affliates</h1>
      <div className="partner-cards">
        {partners.map((partner, index) => (
          <PartnerCard key={index} partner={partner} />
        ))}
      </div>
    </div>
  );
};

export default PartnersPage;

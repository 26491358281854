import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import NavBar from './NavBar'
import NotFoundPage from './pages/404Page'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArticlesListPage from './pages/ArticlesListPage';
import ArticlePage from './pages/Articles';
import LoginPage from './pages/LoginPage';
import CreateAccountPage from './pages/CreateAccountPage';
import Games from './pages/Games';
import AdminDashboard from './pages/AdminDashboard';
import GamesDB from './pages/GamesDB';
import Apps from './pages/Apps';
import AppLauncher from './pages/AppLauncher';
import Footer from './Footer';
import SearchGames from './components/SearchGames';
import IframeDetector from './components/IframeDetector';
import PartnersPage from './pages/PartnersPage';
import LoadingPage from './pages/LoadingPage';
import ArticlesNew from './pages/ArticlesNew';
import Announcements from './components/Announcements';
function App() {
  //          <Route path="/play/:swfId/:imgId?/:gameName?/:isTrending?/:type?" element={<Games />}/>
// <Route path="/apps" element={<Apps />}/>
//<Route path="/apps/:appId" element={<AppLauncher />}/>
  return (
    <BrowserRouter>
    <div className="App">

      <NavBar />
      <Announcements />
       <div id="page-body">
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/announcements" element={<AboutPage />}/>
          <Route path="*" element={<NotFoundPage />}/>
          <Route path="/articles" element={<ArticlesListPage />}/>
          <Route path="/articles/:articleId" element={<ArticlesNew />}/>
          <Route path="/login" element={<LoginPage />}/>
          <Route path="/create-account" element={<CreateAccountPage />}/>
          <Route path="/play" element={<GamesDB />}/>
          <Route path="/play/:swfId" element={<Games />}/>
          <Route path="/search" element={<SearchGames />}/>
          <Route path="/partners" element={<PartnersPage />}/>
          <Route path="/g" element={<GamesDB />}/>
          <Route path="/loading" element={<LoadingPage />}/>


        </Routes>
        <Footer />

       </div>
           <IframeDetector />
           
    </div>
    </BrowserRouter>


  );
}

export default App;

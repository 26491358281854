 
const gamedata = [
    {
        "dateAdded" : "2023-01-07",
         "description": "Play Slope Game, an endless space run game. Drive a ball in the 3D running game in Slope Game. Easy to controls, high speed, and addictive gameplay.",
         "isTrending": "isTrending",
         "name": "Slope Game",
         "gameType": "html5",
         "path": "sg/",
         "img": "img1.jpg",
     },
    {
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/3325334/index.html",
        "img": "2D-Rocket-League.png",
        "name": "2D Rocket League ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://lagged.com/api/play2/t-rex-3d2/",
        "img": "trex-run-3D.webp",
        "name": "3D Dino Game ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.coolmathgames.com/0-60-second-burger-run/play",
        "img": "60-second-burger-run.png",
        "name": "60 Sec. Burger Run ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },   {
        "path": "https://static.arcadespot.com/retroemulator.php?system=nds&amp;game=2017/10/animal-crossing-wild-world1.zip",
        "img": "acww.png",
        "name": "AC - Wild World ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://than1089.github.io/adventure-capitalist/",
        "img": "adventure-capitalist.webp",
        "name": "Adventure Capitalist ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://turbowarp.org/523967150/fullscreen",
        "img": "scratch-among-us.png",
        "name": "Among Us (Scratch) ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://play.geforcenow.com/games?game-id=cb2b1b5f-54ba-45fd-9839-96bbfe1376cd&amp;lang=en_US&amp;asset-id=01_c6efce00-e91e-402a-8b72-f4971f89c528",
        "img": "apex.png",
        "name": "Apex Legends ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamedistribution.com/rvvASMiM/bf1268dccb5d43e7970bb3edaa54afc8/index.html",
        "img": "br.webp",
        "name": "Basket Random ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamedistribution.com/69d78d071f704fa183d75b4114ae40ec/",
        "img": "basketball-stars.png",
        "name": "Basketball Stars ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.basketbros.io/",
        "img": "basket-bros.png",
        "name": "Basketball Bros ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "https://www.basketballlegends.fun/gamedata/basketball-legends-2020",
        "img": "basketball-legends.png",
        "name": "Basketball Legends ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "hhttps://scratch.mit.edu/projects/163771748/fullscreen",
        "img": "biggiecheese.png",
        "name": "Biggie Cheese Fight ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://xlegends.github.io/bitlife/",
        "img": "bitlife.png",
        "name": "Bitlife ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "https://en.gameslol.net/data/bloons-td-4/index.html",
        "img": "112.png",
        "name": "BTD 4 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://games.crazygames.com/en_US/buildnow-gg/index.html",
        "img": "build-now.png",
        "name": "BuildNow.GG ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://4iapq88o5f3gc1dij3it0mp5jojnm3jr-a-sites-opensocial.googleusercontent.com/gadgets/ifr?url=https://s3.amazonaws.com/production-assetsbucket-8ljvyr1xczmb/1ee20621-61bc-4ec8-a8ec-5e839c2e6edc%252Fcat-ninja.xml",
        "img": "cat-ninja.webp",
        "name": "Cat Ninja ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://candybox2.net",
        "img": "candybox.png",
        "name": "Candy Box ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://exok.com/minigames/celeste.html",
        "img": "celeste.webp",
        "name": "Celeste PICO-8 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.clickerheroes.com/play.html",
        "img": "clickerheros.png",
        "name": "Clicker Heros ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://chess.com",
        "img": "chess.png",
        "name": "Chess.com (Fixed) ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://5dd2e1e3-015f-11ea-ad56-9cb6d0d995f7.poki-gdn.com/169dc11d-e718-4b36-9e60-d5ed5bc07a31/index.html",
        "img": "crossy.avif",
        "name": "Crossy Road (Fixed)",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/4017918/index.html",
        "img": "deepest-sword.png",
        "name": "Deepest Sword ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://diep.io/",
        "img": "diep.png",
        "name": "Diep.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://dogeminer.se/",
        "img": "doge-miner-1.png",
        "name": "Doge Miner 1 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#dm",
        "img": "doom1.png",
        "name": "Doom 1 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#dm2",
        "img": "doom2.png",
        "name": "Doom 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://donitz.itch.io/dreader",
        "img": "dreader.png",
        "name": "Dreader ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "https://webglmath.github.io/drift-hunters/",
        "img": "drift-hunters.png",
        "name": "Drift Hunters ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "http://raw.githack.com/3kh0/3kh0-assets/main/minecraft-18/index.html",
        "img": "grass.png",
        "name": "Eaglercraft 1.8 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://jonasz-o.itch.io/fallout2remake3d",
        "img": "fallout2.gif",
        "name": "Fallout 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://now.gg/play/electronic-arts/1353/fifa-soccer",
        "img": "fifa.png",
        "name": "Fifa Soccer ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://play.geforcenow.com/games?game-id=46bfab06-d864-465d-9e56-2d9e45cdee0a&amp;lang=en_US&amp;asset-id=01_15494ab6-efdd-4280-acbc-c740673f17b4",
        "img": "fortnite.png",
        "name": "Fortnite ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://scratch.mit.edu/projects/469219637/embed/",
        "img": "fnaf2.webp",
        "name": "FNAF 2 (Scratch) ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://w8.snokido.com/games/html5/friday-night-funkin/0281/index.html",
        "img": "fnf.png",
        "name": "Friday Night Funkin' ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://fnf.kdata1.com/lofi-funkin/2/",
        "img": "lofi.png",
        "name": "FNF - Lofi Mod ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://fnf.kdata1.com/snorlax/1/",
        "img": "snorlax.png",
        "name": "FNF VS. Snorlax ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://justfall.lol",
        "img": "just-fall-lol.png",
        "name": "JustFall.LOL ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.retrogames.onl/gba/kirby-mirror-gba.html",
        "img": "kirby.webp",
        "name": "Kirby Mirror (GBA) ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://5dd24442-015f-11ea-ad56-9cb6d0d995f7.poki-gdn.com/4f2c69b4-3edc-4cd7-a078-efd3d1ea9fb5/index.html",
        "img": "gswitch.webp",
        "name": "G-Switch ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://5dd27095-015f-11ea-ad56-9cb6d0d995f7.poki-gdn.com/e0e70ee4-fdd4-4de8-931d-fde7d1cb408b/index.html",
        "img": "gswitch2.webp",
        "name": "G-Switch 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://5dd2b395-015f-11ea-ad56-9cb6d0d995f7.poki-gdn.com/120fdec6-7eeb-470f-a43c-9bcdace0dacb/index.html",
        "img": "gswitch3.webp",
        "name": "G-Switch 3 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.kbhgames.com/r/n64/game.php?file=007-golden-eye.zip",
        "img": "golden-eye-007.png",
        "name": "Golden Eye 007 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/",
        "img": "gba.webp",
        "name": "GBA Games ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://cattn.github.io/gba/",
        "img": "gba.webp",
        "name": "GBA Games 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "https://games.crazygames.com/en_US/haunted-school---horror-game/index.html",
        "img": "na.png",
        "name": "Haunted School 1 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "https://hole-io.com/",
        "img": "hole.png",
        "name": "Hole.IO ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "hhttps://play.isleward.com",
        "img": "isleward.png",
        "name": "Isleward ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/ruffle/player.php?id=204",
        "img": "interactive-buddy.png",
        "name": "Interactive Buddy ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "https://www.gameslol.net/data/waflash/index.php?g=635",
        "img": "ltf_idle.webp",
        "name": "Learn To Fly Idle ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://lordz.io/",
        "img": "lordz.png",
        "name": "Lordz.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://unblocked-games.s3.amazonaws.com/games/masked-io/index.html",
        "img": "masked-forces.webp",
        "name": "Masked IO ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "https://static.arcadespot.com/retroemulator.php?system=n64&amp;game=2017/06/mario-kart-64.zip",
        "img": "mario-kart-64.webp",
        "name": "Mario Kart 64 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://overboy.itch.io/mobs-inc",
        "img": "mobsinc.png",
        "name": "Mobs Inc ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamemonetize.co/ugi7ftbv2kgodcq7vful9u9v34wein5z/",
        "img": "mm.png",
        "name": "Monkey Mart ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://moomoo.io",
        "img": "moo.png",
        "name": "MooMoo.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "https://f.kbhgames.com/r/n64/game.php?file=Mortal-Kombat-4-U.zip",
        "img": "na.png",
        "name": "Mortal Kombat 4 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.coolmathgames.com/0-mr-mine/play",
        "img": "mrmine.png",
        "name": "Mr.Mine ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamedistribution.com/rvvASMiM/5b0abd4c0faa4f5eb190a9a16d5a1b4c/index.html",
        "img": "mx3m.webp",
        "name": "Moto X3M ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://h0jokl1egt0fd4oc8qv3j0tltl9jbqhn-a-sites-opensocial.googleusercontent.com/gadgets/ifr?url=https://649025137-174029463385024710.preview.editmysite.com/uploads/b/139890129-767696982876512205/files/mx3mpp.xml",
        "img": "mx3m.webp",
        "name": "MX3M: Pool Party ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamedistribution.com/rvvASMiM/b8a342904608470a9f3382337aca3558/index.html",
        "img": "mx3m-spooky.png",
        "name": "MX3M: Spooky Land ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www-sites-opensocial.googleusercontent.com/gadgets/ifr?url=https://sites.google.com/site/s017q3e/moto-x3m-4-winter.xml",
        "img": "mx3m-winter.webp",
        "name": "MX3M: Winter ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://complex-ify.itch.io/saul-goodman",
        "img": "saulrun.png",
        "name": "Saul Run ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, /*{
        "path": "https://interstellarnetwork.github.io/interstellar-assets/play/ng-space-company/frontend/dist/index.html",
        "img": "ng.png",
        "name": " NG Space Company",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },*/ {
        "path": "https://html5.gamedistribution.com/rvvASMiM/903ba9346b9d437e9c7e81d672cead44/index.html",
        "img": "ninja-cat.webp",
        "name": "Ninja Cat Exploit ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://8rlfg0ch3417et18dp8lvps6uo7c3b2c-a-sites-opensocial.googleusercontent.com/gadgets/ifr?url=https://427396048-642845047394716217.preview.editmysite.com/uploads/b/139890129-761103484729797659/files/ovo.xml",
        "img": "ovo.png",
        "name": "OvO ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://jchabin.github.io/cars/",
        "img": "na.png",
        "name": "Online Racing Game!",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.onlinesoccermanager.com/",
        "img": "osm.webp",
        "name": "Online Soccer M. ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/emu/waffle/?id=5458",
        "img": "bakeria.webp",
        "name": "Papa's Bakeria ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/emu/waffle/?id=3246",
        "img": "cupcakeria.png",
        "name": "Papa's Cupcakeria ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/ruffle/player.php?id=1373",
        "img": "louie1.png",
        "name": "Papa Louie 1 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/emu/waffle/?id=3042",
        "img": "louie2.png",
        "name": "Papa Louie 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/emu/waffle/?id=4693",
        "img": "papa-louie-3.webp",
        "name": "Papa Louie 3 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.silvergames.com/ruffle/player.php?id=1360",
        "img": "pizzeria.png",
        "name": "Papa's Pizzeria ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.kbhgames.com/r/n64/game.php?file=Paper%20Mario%20(USA).zip",
        "img": "paper-mario-64.webp",
        "name": "Paper Mario 64 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://paper-io.com/",
        "img": "paperio.webp",
        "name": "Paper.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": "isTrending",
    }, {
        "path": "https://94bfktj403i6m18as4vkvtreqd0ohci4-a-sites-opensocial.googleusercontent.com/gadgets/ifr?url=https://274019683-173520394482650759.preview.editmysite.com/uploads/b/139890129-131715539788281629/files/ps.xml",
        "img": "pixel-shooter.png",
        "name": "Pixel Shooter ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://gamaverse.com/c/f/g/pizza-tower-1678640389/index.html",
        "img": "pizza-tower.webp",
        "name": "Pizza Tower ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://static.arcadespot.com/retroemulator.php?system=nds&amp;game=2017/10/pokemon-heartgold-version1.zip",
        "img": "heartgold.webp",
        "name": "Pokemon Heart Gold ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://play.pokemonshowdown.com",
        "img": "showdown.png",
        "name": "Pokemon Showdown ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#pokemonlp",
        "img": "lp.webp",
        "name": "PM: Light Platinum ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "http://raw.githack.com/3kh0/3kh0-assets/main/precision-client/index.html",
        "img": "precision.png",
        "name": "Precision Client ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://play.geforcenow.com/games?game-id=1dd07d47-6601-42f7-80e9-e4d8db08ea1b&amp;lang=en_US&amp;asset-id=01_44417-48c3d8e642e2",
        "img": "r6.webp",
        "name": "Rainbow Six Siege ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.algebrashelper.com/redball",
        "img": "redball1.png",
        "name": "Red Ball 1 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.algebrashelper.com/redball-2",
        "img": "redball2.png",
        "name": "Red Ball 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.algebrashelper.com/redball-4",
        "img": "redball4.png",
        "name": "Red Ball 4 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.algebrashelper.com/read-ball-4v2",
        "img": "redball4vol2.png",
        "name": "Red Ball 4 Vol. 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.algebrashelper.com/red-ball-4v3",
        "img": "redball4vol3.png",
        "name": "Red Ball 4 Vol. 3 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://binbashbanana.github.io/webretro/",
        "img": "retroarch.png",
        "name": "Retro Arch ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },
    {
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/565140/index.html",
        "img": "rocketpult.webp",
        "name": "Rocket Pult ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://html5.gamedistribution.com/rvvASMiM/c3a70ae98547407a92ebedca8b79fdfa/index.html",
        "img": "rooftop.webp",
        "name": "Rooftop Snipers ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.coolmathgames.com/0-run-3/play",
        "img": "run3.png",
        "name": "Run 3 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://sandspiel.club/",
        "img": "sand.webp",
        "name": "Sand Spiel ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/5808591/index.html",
        "img": "sandboxels.webp",
        "name": "Sandboxels ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://shapez.io",
        "img": "shapezio.png",
        "name": "Shapez.IO ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://shellshock.io/",
        "img": "shell-shockers.png",
        "name": "Shell Shockers ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "http://slither.io/",
        "img": "slither.png",
        "name": "Slither Io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://emulatorgames.online/games/n64/super-smash-bros",
        "img": "super-smash-bros-64.webp",
        "name": "Smash Bros 64 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://smashkarts.io/",
        "img": "smashkarts.png",
        "name": "Smash Karts ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://games.crazygames.com/en_US/snowball-io/index.html",
        "img": "snowball.webp",
        "name": "Snowball.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://cowsssss.github.io/Space/",
        "img": "na.png",
        "name": "Space ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "http://jhollands.co.uk/spaceplan/",
        "img": "spaceplan.png",
        "name": "Space Plan ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },
    
    {
        "path": "https://stumble-guys.io/stumble-guys.embed",
        "img": "stumble-guys.webp",
        "name": "Stumble Guys Clone ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },  {
        "path": "https://66564262-37c6-4095-a731-535342e4bbe4.poki-gdn.com/5bd6e8c6-381d-4de5-9823-96662d29afaf/index.html",
        "img": "sugarsugar.png",
        "name": "Sugar Sugar HTML5 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.numuki.com/gameframe/super-mario-63",
        "img": "sm63.png",
        "name": "Super Mario 63 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.kbhgames.com/r/n64/game.php?file=32112_super-mario-64-usa.zip",
        "img": "sm64.png",
        "name": "Super Mario 64 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://f.kbhgames.com/RS/game.php?r=//f.kbhgames.com/2018/swf/smashflash.swf&amp;w=1521&amp;h=753",
        "img": "ssf1.png",
        "name": "Super Smash Flash ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "http://chat.kongregate.com/gamez/0027/1653/live/index.html?kongregate_game_version=1554392772",
        "img": "na.png",
        "name": "Supply Chain Idle ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://school-homework.com/",
        "img": "tamingio.webp",
        "name": "Taming.io ",
        "dateAdded": "2023-10-16",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://watchdocumentaries.com/wp-content/uploads/games/tanuki-sunset",
        "img": "tanuki.png",
        "name": "Tanuki Sunset ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://static.arcadespot.com/retroemulator.php?system=nds&amp;game=2017/11/the-simpsons-game.zip",
        "img": "the-simpsons-game.png",
        "name": "The Simpsons ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://watchdocumentaries.com/wp-content/uploads/games/temple-run-2/",
        "img": "temple-run-2.png",
        "name": "Temple Run 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://leognon.com/tritris/",
        "img": "tritis.png",
        "name": "Tritis ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#thps2",
        "img": "thps2.webp",
        "name": "TH's Pro Skater 2 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#thps3",
        "img": "thps3.webp",
        "name": "TH's Pro Skater 3 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://browncha023.github.io/GBA/launcher.html#thps4",
        "img": "thps4.webp",
        "name": "TH's Pro Skater 4 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://www.rossipotti.de/ausgabe28/tetris/controls.html",
        "img": "na.png",
        "name": "Twitch Tetris ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://raw.githack.com/3kh0/3kh0-assets/main/vex5/index.html",
        "img": "vex5.png",
        "name": "Vex 5 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://interstellarnetwork.github.io/interstellar-assets/play/vex7/index.html",
        "img": "vex7.png",
        "name": "Vex 7 ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://voxiom.io/",
        "img": "voxiom.webp",
        "name": "Voxiom.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": 'https://zombs.io/',
        "img": "zombs-io.png",
        "name": "Zombs.io ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    }, {
        "path": "https://zombsroyale.io/",
        "img": "zombs-royale.png",
        "name": "Zombs Royale ",
        "dateAdded": "2023-05-09",
        "description": "",
        "gameType": "external",
        "isTrending": false,
    },
    {
       "dateAdded" : "2023-02-07",
        "description": "A modern twist on the classic Atari game!!",
        "isTrending": false,
        "name": "Idle Breakout",
        "gameType": "html5",
        "path": "idle-breakout",
        "img": "idle.png",
    },
    {
        "dateAdded" : "2023-01-07",
        "description": "Getaway Shootout is a fun and chaotic multiplayer game where players compete to be the last one standing. With different characters and power-ups to unlock, it's a game that's easy to pick up and hard to put down",
        "isTrending": false,
        "name": "Getaway Shootout",
        "gameType": "html5",
        "path": "getaway-shootout",
        "img": "index.jpg",
        },
        /*
        {
        "dateAdded" : "2023-01-07",
        "description": "Retro Bowl is a throwback to classic football games of the 80s and 90s. With simple controls and addictive gameplay, it's easy to see why this game is so popular.",
        "isTrending": true,
        "name": "Retro Bowl",
        "gameType": "html5",
        "path": "retro-bowl-main",
        "img": "retro-bowl.jpg",
        },*/ {
            "dateAdded" : "2023-9-30",
            "description": "Retro Bowl is a throwback to classic football games of the 80s and 90s. With simple controls and addictive gameplay, it's easy to see why this game is so popular.",
            "isTrending": true,
            "name": "Retro Bowl",
            "gameType": "external",
            "path": "https://math.bigfootshack.net/",
            "img": "retro-bowl.jpg",
            },
        {
            "dateAdded" : "2023-04-08",
            "description": "A popular endless arcade game where you must navigate across a busy road, avoiding obstacles and collecting coins along the way.",         
            "isTrending": true,
            "name": "Crossy Road",
            "gameType": "html5",
            "path": "crossyroad",
            "img": "crossy.avif",
            },
        {
        "dateAdded" : "2023-03-10",
        "description": "Papa's Pizzeria puts you in charge of a busy pizza restaurant. With dozens of toppings and ingredients to choose from, it's up to you to keep your customers happy and your kitchen running smoothly.",
        "isTrending": true,
        "name": "Papa's Pizzeria",
        "path": "PapasPizzeria.swf",
        "img": "papas-pizza",
        "gameType": "flash",

        },
        {
        "dateAdded" : "2023-01-07",
        "description": "In Papa's Burgeria, you're in charge of a burger joint where you can build and customize burgers to your heart's content. With different sauces and toppings to experiment with, it's a game that's sure to satisfy your appetite for fun.",
        "isTrending": true,
        "name": "Papa's Burgeria",
        "path": "waflash/papas-burgeria/",
        "img": "img64",
        "gameType": "waflash",

        },
        {
        "dateAdded" : "2023-01-07",
        "description": "Papa's Hot Doggeria is all about making the perfect hot dog for your customers. With different types of buns, sausages, and condiments to choose from, it's a game that's both delicious and entertaining.",
        "isTrending": true,
        "name": "Papa's HotDoggeria",
        "path": "waflash/papas-hot-doggeria/",
        "img": "papas-hotdog.png",
        "gameType": "waflash",

        },
        {
        "dateAdded" : "2023-01-07",
        "description": "In Papa's Wingeria, you're in charge of a busy chicken wing restaurant. With different flavors and sauces to experiment with, it's a game that's perfect for foodies and gamers alike.",
        "isTrending": true,
        "name": "Papa's Wingeria",
        "path": "waflash/papas-wingeria/",
        "img": "wingeria.jpg",
        "gameType": "waflash",

        },
        {
        "dateAdded" : "2023-01-07",
        "description": "Papa's Freezeria is a cool and refreshing game where you get to make and serve delicious ice cream sundaes to your customers. With different flavors and toppings to choose from, it's a game that's sure to satisfy your sweet tooth.",
        "isTrending": true,
        "name": "Papa's Freezeria",
        "path": "waflash/papas-freezeria/",
        "img": "freezeria.jpg",
        "gameType": "waflash",

        },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Pancakeria",
        "path": "waflash/papas-Pancakeria/",
        "img": "pancakeria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Sushiria",
        "path": "waflash/papas-Sushiria/",
        "img": "sushiria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Bakeria",
        "path": "waflash/papas-bakeria/",
        "img": "bakeria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Cheeseria",
        "path": "waflash/papas-cheeseria/",
        "img": "cheeseria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Cupcakeria",
        "path": "waflash/papas-cupcakeria/",
        "img": "cupcakeria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Donuteria",
        "path": "waflash/papas-donuteria/",
        "img": "donuteria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Pastaria",
        "path": "waflash/papas-pastaria/",
        "img": "pastaria.jpg",
        "gameType": "waflash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papas Scooperia",
        "path": "waflash/papas-scooperia/",
        "img": "scooperia.jpg",
        "gameType": "waflash",

    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "1 on 1 soccer",
        "path": "1on1soccer.swf",
        "gameType": "flash",
        "img": "img91.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ngon",
        "gameType": "html5",
        "path": "n-gon/",
        "img": "img26.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Battlepanic",
        "path": "battlepanic.swf",
        "gameType": "flash",
        "img": "img105.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonsplayerpack2",
        "path": "bloonsplayerpack2.swf",
        "gameType": "flash",
        "img": "img106.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonsplayerpack3",
        "path": "bloonsplayerpack3.swf",
        "gameType": "flash",
        "img": "img107.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonsplayerpack4",
        "path": "bloonsplayerpack4.swf",
        "gameType": "flash",
        "img": "img108.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonsplayerpack5",
        "path": "bloonsplayerpack5.swf",
        "gameType": "flash",
        "img": "img109.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonstd1",
        "path": "bloonstd1.swf",
        "gameType": "flash",
        "img": "img110.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonstd3",
        "path": "bloonstd3.swf",
        "gameType": "flash",
        "img": "img111.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonstd4",
        "path": "bloonstd4.swf",
        "gameType": "flash",
        "img": "112.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloonstd5",
        "path": "bloonstd5.swf",
        "gameType": "flash",
        "img": "113.png",
    },
    {
       "dateAdded" : "2023-03-13",
        "description": "server 1",
        "isTrending": false,
        "name": "Bob the Robber",
        "path": "https://z06by.netlify.app/game/bob-the-robber-2/index.html",
        "gameType": "external",
        "img": "img114.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Boombot2",
        "path": "boombot2.swf",
        "gameType": "flash",
        "img": "img115.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Boxhead2play",
        "path": "boxhead2.swf",
        "img": "img116.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bubbletanks2",
        "path": "bubbletanks2.swf",
        "gameType": "flash",
        "img": "img117.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bulletbill",
        "path": "bulletbill.swf",
        "gameType": "flash",
        "img": "img118.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Burritobison",
        "path": "burritobison.swf",
        "gameType": "flash",
        "img": "121.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Burrito bison revenge",
        "path": "burritobisonrevenge.swf",
        "gameType": "flash",
        "img": "img122.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cactus mccoy",
        "path": "cactus mccoy.swf",
        "gameType": "flash",
        "img": "img123.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cactusmccoy2",
        "path": "cactusmccoy2.swf",
        "gameType": "flash",
        "img": "img124.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cannon basketball 2",
        "path": "cannonbasketball2.swf",
        "gameType": "flash",
        "img": "img125.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cargobridge",
        "path": "cargobridge.swf",
        "gameType": "flash",
        "img": "img126.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Causality",
        "path": "causality.swf",
        "gameType": "flash",
        "img": "img127.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Chibi knight",
        "path": "chibiknight.swf",
        "gameType": "flash",
        "img": "img128.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Computer bashing",
        "path": "computerbashing.swf",
        "gameType": "flash",
        "img": "img130.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Crush the castle",
        "path": "crushthecastle.swf",
        "gameType": "flash",
        "img": "img131.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "3d Tanks",
        "path": "3dtanks.swf",
        "gameType": "flash",
        "img": "img92.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Abobos big adventure",
        "path": "abobosbigadventure.swf",
        "gameType": "flash",
        "img": "img93.jpg",
    },
    /*{
    "isTrending":false,
           "name": "Achievementunlocked",
           "path": "achievementunlocked.swf",
"gameType": "flash",
           "img" : "img119.webp",
       }, 
       {
    "isTrending":false,
           "name": "Achievementunlocked2",
           "path": "achievementunlocked2.swf",
"gameType": "flash",
           "img" : "img95.png",
       },                     
       {
    "isTrending":false,
           "name": "Achievementunlocked3",
           "path": "achievementunlocked3.swf",
"gameType": "flash",
           "img" : "img96.jpg",
       },*/
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Actionturnip",
        "path": "actionturnip.swf",
        "gameType": "flash",
        "img": "img97.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Adaran",
        "path": "adaran.swf",
        "gameType": "flash",
        "img": "img150.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Adrenaline",
        "path": "adrenaline.swf",
        "gameType": "flash",
        "img": "img98.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "American racing 1",
        "path": "americanracing1.swf",
        "gameType": "flash",
        "img": "img99.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "American racing 2",
        "path": "americanracing2.swf",
        "gameType": "flash",
        "img": "img100.webp",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Armyofages",
        "path": "armyofages.swf",
        "gameType": "flash",
        "img": "img102.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Awesome cars",
        "path": "awesomecars.swf",
        "gameType": "flash",
        "img": "img103.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Awesome planes",
        "path": "awesomeplanes.swf",
        "gameType": "flash",
        "img": "img104.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "2048",
        "gameType": "html5",
        "path": "2048/",
        "img": "img32.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Dark Room",
        "gameType": "html5",
        "path": "adarkroom/",
        "img": "img69.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Asteroids",
        "gameType": "html5",
        "path": "asteroids/",
        "img": "img70.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Astray",
        "gameType": "html5",
        "path": "astray/",
        "img": "img71.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Black Hole Square",
        "gameType": "html5",
        "path": "blackholesquare/",
        "img": "img72.jpg",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bounce Back",
        "gameType": "html5",
        "path": "bounceback/",
        "img": "img73.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "Break the lock by guessing the correct combination!",
        "isTrending": "isTrending",
        "name": "Break Lock",
        "gameType": "html5",
        "path": "breaklock/",
        "img": "img74.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Breakout",
        "gameType": "html5",
        "path": "breakout/",
        "img": "img75.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Chess",
        "gameType": "html5",
        "path": "chess/",
        "img": "img77.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Chrome Dino",
        "gameType": "html5",
        "path": "chromedino/",
        "img": "img78.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Connect 4",
        "gameType": "html5",
        "path": "connect3/",
        "img": "img78.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Evil Glitch",
        "gameType": "html5",
        "path": "evilglitch/",
        "img": "img80.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Geometry Dash",
        "gameType": "html5",
        "path": "geometrydash/",
        "img": "img33.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Packabunchas",
        "gameType": "html5",
        "path": "packabunchas/",
        "img": "img82.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Particle Clicker",
        "gameType": "html5",
        "path": "particleclicker/",
        "img": "img83.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pac Man",
        "gameType": "html5",
        "path": "pacman/",
        "img": "img173.jpeg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Radius Raid",
        "gameType": "html5",
        "path": "radiusraid/",
        "img": "87.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Space Garden",
        "gameType": "html5",
        "path": "spacegarden/",
        "img": "img88.jpg",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tower Master",
        "gameType": "html5",
        "path": "towermaster/",
        "img": "img89",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "xx142-b2.exe",
        "gameType": "html5",
        "path": "xx142-b2exe/",
        "img": "img90.webp",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Crush the castle 2",
        "path": "crushthecastle2.swf",
        "gameType": "flash",
        "img": "img132.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cubefield",
        "path": "cubefield.swf",
        "gameType": "flash",
        "img": "img133.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cyclo maniacs 2",
        "path": "cyclomaniacs2.swf",
        "gameType": "flash",
        "img": "img134.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Diggy",
        "path": "diggy.swf",
        "gameType": "flash",
        "img": "img135.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Donkeykong",
        "path": "donkeykong.swf",
        "gameType": "flash",
        "img": "img136.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Konnekt",
        "gameType": "html5",
        "path": "konnekt/",
        "img": "img81.jpg",
    },
    /*{
    "isTrending":false,
           "name": "Dontshootthepuppy",
           "path": "dontshootthepuppy.swf",
"gameType": "flash",
           "img" : "img137.png",
       },*/
    //this game is terrible lol
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Doodle defender",
        "path": "doodledefender.swf",
        "gameType": "flash",
        "img": "img138.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Doom",
        "path": "doom.swf",
        "gameType": "flash",
        "img": "img139.jfif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Dragracing",
        "path": "dragracing.swf",
        "gameType": "flash",
        "img": "img140.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ducklife",
        "path": "ducklife.swf",
        "gameType": "flash",
        "img": "img141.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ducklife2",
        "path": "ducklife2.swf",
        "gameType": "flash",
        "img": "img142.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ducklife3",
        "path": "ducklife3.swf",
        "gameType": "flash",
        "img": "img143.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ducklife4",
        "path": "ducklife4.swf",
        "gameType": "flash",
        "img": "img144.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Earntodie",
        "path": "earntodie.swf",
        "gameType": "flash",
        "img": "img145.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Earntodie2",
        "path": "earntodie2.swf",
        "gameType": "flash",
        "img": "img146.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Earn to die superwheel",
        "path": "earntodiesuperwheel.swf",
        "gameType": "flash",
        "img": "img147.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Electricman 2",
        "path": "electricman2.swf",
        "gameType": "flash",
        "img": "img148.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Elephant quest",
        "path": "elephantquest.swf",
        "gameType": "flash",
        "img": "img149.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Epic battle fantasy 3",
        "path": "epicbattlefantasy3.swf",
        "gameType": "flash",
        "img": "img150.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Epic comboredux",
        "path": "epiccomboredux.swf",
        "gameType": "flash",
        "img": "img151.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Exitpath",
        "path": "exitpath.swf",
        "gameType": "flash",
        "img": "img152.jfif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Factory balls",
        "path": "factoryballs.swf",
        "gameType": "flash",
        "img": "img153.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Factory balls 2",
        "path": "factoryballs2.swf",
        "gameType": "flash",
        "img": "img154.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Factory balls 3",
        "path": "factoryballs3.swf",
        "gameType": "flash",
        "img": "img155.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Factory balls 4",
        "path": "factoryballs4.swf",
        "gameType": "flash",
        "img": "img156.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Flash flight simulator",
        "path": "flashflightsimulator.swf",
        "gameType": "flash",
        "img": "157.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Freerider2",
        "path": "freerider2.swf",
        "gameType": "flash",
        "img": "img158.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Johnny Upgrade",
        "path": "johnnyupgrade.swf",
        "gameType": "flash",
        "img": "img159.jpg",
    },
 
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fancy pants adventure",
        "path": "fancypantsadventure.swf",
        "gameType": "flash",
        "img": "fancypants.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fancy pants adventure2",
        "path": "fancypantsadventure2.swf",
        "gameType": "flash",
        "img": "img184.jpg"
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fancy pants adventure3",
        "path": "fancypantsadventure3.swf",
        "gameType": "flash",
        "img": "img185.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Manole",
        "path": "flight.swf",
        "gameType": "flash",
        "img": "manole.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fracuum",
        "path": "fracuum.swf",
        "gameType": "flash",
        "img": "FRACUM.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Giveuprobot",
        "path": "giveuprobot.swf",
        "gameType": "flash",
        "img": "img186.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Giveuprobot2",
        "path": "giveuprobot2.swf",
        "gameType": "flash",
        "img": "img187.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hanger",
        "path": "hanger.swf",
        "gameType": "flash",
        "img": "img167.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hanger2",
        "path": "hanger2.swf",
        "gameType": "flash",
        "img": "img168",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Happywheels",
        "path": "happywheels.swf",
        "gameType": "flash",
        "img": "img166",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Hobo",
        "path": "hobo.swf",
        "gameType": "flash",
        "img": "img183.jpeg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Hobo2",
        "path": "hobo2.swf",
        "gameType": "flash",
        "img": "img188.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Hobo3",
        "path": "hobo3.swf",
        "gameType": "flash",
        "img": "img189.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Hobo4",
        "path": "hobo4.swf",
        "gameType": "flash",
        "img": "img190.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo5",
        "path": "hobo5.swf",
        "gameType": "flash",
        "img": "img191.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo6",
        "path": "hobo6.swf",
        "gameType": "flash",
        "img": "img192.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo7",
        "path": "hobo7.swf",
        "gameType": "flash",
        "img": "img169.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Houseofwolves",
        "path": "houseofwolves.swf ",
        "img": "img193.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Interactivebuddy",
        "path": "interactivebuddy.swf",
        "gameType": "flash",
        "img": "img194.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Jacksmith",
        "path": "jacksmith.swf",
        "gameType": "flash",
        "img": "img175.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Jellytruck",
        "path": "jellytruck.swf",
        "gameType": "flash",
        "img": "img195.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Jumpix2",
        "path": "jumpix2.swf",
        "gameType": "flash",
        "img": "jumpix2.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Knightmaretower",
        "path": "knightmaretower.swf",
        "gameType": "flash",
        "img": "img196.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Learn2fly ALT",
        "path": "https://www.gameslol.net/data/waflash/index.php?g=635",
        "gameType": "external",
        "img": "img162.jpg",
    },
    {
        "dateAdded" : "2023-01-07",
        "description": "",
         "isTrending": false,
         "name": "Rocket Leauge",
         "path": "https://astroidv2.vyper.group/worksheets/main/Rocket-League/index.html",
         "gameType": "external",
         "img": "rl.jpg",
     },
    {
       "dateAdded" : "2024-01-08",
        "description": "",
        "isTrending": "isTrending",
        "name": "Learn2fly2",
        "path": "learn2fly2.swf",
        "gameType": "flash",
        "img": "img164.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Learn2fly3",
        "path": "learn2fly3.swf",
        "gameType": "flash",
        "img": "img163.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Magnetface",
        "path": "magnetface.swf",
        "gameType": "flash",
        "img": "img198.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mariocombat",
        "path": "mariocombat.swf",
        "gameType": "flash",
        "img": "img199.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mario Racing",
        "path": "marioracingtournament.swf",
        "gameType": "flash",
        "img": "200.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Meatboy",
        "path": "meatboy.swf",
        "gameType": "flash",
        "img": "201.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Megamanprojectx",
        "path": "megamanprojectx.swf",
        "gameType": "flash",
        "img": "img202.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Metroidelements",
        "path": "metroidelements.swf",
        "gameType": "flash",
        "img": "me.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mineblocks",
        "path": "mineblocks.swf",
        "gameType": "flash",
        "img": "img203.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Minesweeper",
        "path": "minesweeper.swf",
        "gameType": "flash",
        "img": "img204.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mirrorsedge",
        "path": "mirrorsedge.swf",
        "gameType": "flash",
        "img": "img205.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Moneymovers",
        "path": "moneymovers.swf",
        "gameType": "flash",
        "img": "img206.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Moneymovers3",
        "path": "moneymovers3.swf",
        "gameType": "flash",
        "img": "img207.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Motherload",
        "path": "motherload.swf",
        "gameType": "flash",
        "img": "img208.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Motox3m",
        "path": "motox3m.swf",
        "gameType": "flash",
        "img": "img165",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Multitask",
        "path": "multitask.swf",
        "gameType": "flash",
        "img": "img210.jfif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mutilateadoll2",
        "path": "mutilateadoll2.swf",
        "gameType": "flash",
        "img": "killdoll.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Myangel",
        "path": "myangel.swf",
        "gameType": "flash",
        "img": "angel.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Nanotube",
        "path": "nanotube.swf",
        "gameType": "flash",
        "img": "nanotube.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Newgroundsrumble",
        "path": "newgroundsrumble.swf",
        "gameType": "flash",
        "img": "img160",
    }, {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ngame",
        "path": "ngame.swf",
        "gameType": "flash",
        "img": "ngame.jpg",
    }, {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Nitromemustdie",
        "path": "nitromemustdie.swf",
        "gameType": "flash",
        "img": "nitrome.jpg",
    }, {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Nucleus",
        "path": "nucleus.swf",
        "gameType": "flash",
        "img": "nuclous.png",
    }, {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Nv2",
        "path": "nv2.swf",
        "gameType": "flash",
        "img": "nv2.webp",
    }, {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Nyancatlostinspace",
        "path": "nyancatlostinspace.swf",
        "gameType": "flash",
        "img": "img161.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Offroaders",
        "path": "offroaders.swf",
        "gameType": "flash",
        "img": "offroaders.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Onemanarmy2",
        "path": "onemanarmy2.swf",
        "gameType": "flash",
        "img": "onemanarmy2.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Outofthisworld",
        "path": "outofthisworld.swf",
        "gameType": "flash",
        "img": "OUTOFTHISWORD.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pacman 1",
        "path": "pacman.swf",
        "gameType": "flash",
        "img": "pacman.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pandemic",
        "path": "pandemic.swf",
        "gameType": "flash",
        "img": "PANDEMIC.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pandemic2",
        "path": "pandemic2.swf",
        "gameType": "flash",
        "img": "img171.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papalouie",
        "path": "papalouie.swf",
        "gameType": "flash",
        "img": "papalouie.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papalouie2",
        "path": "papalouie2.swf",
        "gameType": "flash",
        "img": "img170.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Papalouie3",
        "path": "papalouie3.swf",
        "gameType": "flash",
        "img": "papa-lou-3.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Picosschool",
        "path": "picosschool.swf",
        "gameType": "flash",
        "img": "school.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Picosschool2",
        "path": "picosschool2.swf",
        "gameType": "flash",
        "img": "school2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pirates",
        "path": "pirates.swf",
        "gameType": "flash",
        "img": "pirates.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Polarjump",
        "path": "polarjump.swf",
        "gameType": "flash",
        "img": "polar.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Portal",
        "path": "portal.swf",
        "gameType": "flash",
        "img": "portal.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Portal2d",
        "path": "portal2d.swf",
        "gameType": "flash",
        "img": "portal2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Quadrobarreldefence",
        "path": "quadrobarreldefence.swf",
        "gameType": "flash",
        "img": "quad.png",
    },


    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Qwop",
        "path": "qwop.swf",
        "gameType": "flash",
        "img": "qwop.png",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Raft wars",
        "path": "raftwars.swf",
        "gameType": "flash",
        "img": "img174.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Raft wars2",
        "path": "raftwars2.swf",
        "gameType": "flash",
        "img": "raftwars2.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Raze",
        "path": "raze.swf",
        "gameType": "flash",
        "img": "raze.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redball",
        "path": "redball.swf",
        "gameType": "flash",
        "img": "img176.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redball2",
        "path": "redball2.swf",
        "gameType": "flash",
        "img": "img177.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redball4",
        "path": "redball4.swf",
        "gameType": "flash",
        "img": "img178.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redball4v2",
        "path": "redball4v2.swf",
        "gameType": "flash",
        "img": "img179.jfif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redball4v3",
        "path": "redball4v3.swf",
        "gameType": "flash",
        "img": "img180.jfif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Redshift",
        "path": "redshift.swf",
        "gameType": "flash",
        "img": "redshift.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Revenant2",
        "path": "revenant2.swf",
        "gameType": "flash",
        "img": "rev2.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddleschool1",
        "path": "riddleschool1.swf",
        "gameType": "flash",
        "img": "riddleschool1.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddleschool2",
        "path": "riddleschool2.swf",
        "gameType": "flash",
        "img": "rs2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddleschool3",
        "path": "riddleschool3.swf",
        "gameType": "flash",
        "img": "rs3.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddleschool4",
        "path": "riddleschool4.swf",
        "gameType": "flash",
        "img": "rs4.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddleschool5",
        "path": "riddleschool5.swf",
        "gameType": "flash",
        "img": "rs5.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddletransfer",
        "path": "riddletransfer.swf",
        "gameType": "flash",
        "img": "rst.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Riddletransfer2",
        "path": "riddletransfer2.swf",
        "gameType": "flash",
        "img": "rst2.jpg",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Run2",
        "path": "run2.swf",
        "gameType": "flash",
        "img": "img181.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Run3",
        "path": "run3.swf",
        "gameType": "flash",
        "img": "img2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Saszombieassault3",
        "path": "saszombieassault3.swf",
        "gameType": "flash",
        "img": "sas.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sentryknight",
        "path": "sentryknight.swf",
        "gameType": "flash",
        "img": "sk.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Shoppingcarthero3",
        "path": "shoppingcarthero3.swf",
        "gameType": "flash",
        "img": "sch3.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Siftheads",
        "path": "siftheads.swf",
        "gameType": "flash",
        "img": "img182.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Siftheads2",
        "path": "siftheads2.swf",
        "gameType": "flash",
        "img": "sha2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Siftheads3",
        "path": "siftheads3.swf",
        "gameType": "flash",
        "img": "sha3.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Siftheads4",
        "path": "siftheads4.swf",
        "gameType": "flash",
        "img": "sha4.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Siftheads5",
        "path": "siftheads5.swf",
        "gameType": "flash",
        "img": "sha5.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sniperassassin4",
        "path": "sniperassassin4.swf",
        "gameType": "flash",
        "img": "sa4.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sportsheadsfootball",
        "path": "sportsheadsfootball.swf",
        "gameType": "flash",
        "img": "shf.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sportsheadsracing",
        "path": "sportsheadsracing.swf",
        "gameType": "flash",
        "img": "sh.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sportsheadstennis",
        "path": "sportsheadstennis.swf",
        "gameType": "flash",
        "img": "sht.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stickrpg",
        "path": "stickrpg.swf",
        "gameType": "flash",
        "img": "rpg.webp",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stickwar",
        "path": "stickwar.swf",
        "gameType": "flash",
        "img": "sw.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Strikeforceheroes2",
        "path": "strikeforceheroes2.swf",
        "gameType": "flash",
        "img": "sfh.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sugarsugar",
        "path": "sugarsugar.swf",
        "gameType": "flash",
        "img": "ss.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sugarsugar2",
        "path": "sugarsugar2.swf",
        "gameType": "flash",
        "img": "ss2.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sugarsugar3",
        "path": "sugarsugar3.swf",
        "gameType": "flash",
        "img": "ss3.avif",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Superfighters",
        "path": "superfighters.swf",
        "gameType": "flash",
        "img": "superfighters.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Supermario63",
        "path": "supermario63.swf",
        "gameType": "flash",
        "img": "sm63.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Supermarioflash",
        "path": "supermarioflash.swf",
        "gameType": "flash",
        "img": "supermarioflash1.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Supermarioflash2",
        "path": "supermarioflash2.swf",
        "gameType": "flash",
        "img": "smf2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Super smash flash",
        "path": "supersmashflash.swf",
        "gameType": "flash",
        "img": "ssf.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Swordsandsandals2",
        "path": "swordsandsandals2.swf",
        "gameType": "flash",
        "img": "sas2.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tacticalassassin",
        "path": "tacticalassassin.swf",
        "gameType": "flash",
        "img": "ta.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tanks",
        "path": "tanks.swf",
        "gameType": "flash",
        "img": "tank.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tanktrouble",
        "path": "tanktrouble.swf",
        "gameType": "flash",
        "img": "tt.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tetris",
        "path": "tetris.swf",
        "gameType": "flash",
        "img": "tetris.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Thegame",
        "path": "thegame.swf",
        "gameType": "flash",
        "img": "tg.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Theimpossiblequiz",
        "path": "theimpossiblequiz.swf",
        "gameType": "flash",
        "img": "tiq.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Theimpossiblequiz2",
        "path": "theimpossiblequiz2.swf",
        "gameType": "flash",
        "img": "tiq2.avif",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Worldshardestgame2",
        "path": "theworldshardestgame2.swf",
        "gameType": "flash",
        "img": "twhg2.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Thingthingarena",
        "path": "thingthingarena.swf",
        "gameType": "flash",
        "img": "tta.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Thisistheonlylevel",
        "path": "thisistheonlylevel.swf",
        "gameType": "flash",
        "img": "titol.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tosstheturtle",
        "path": "tosstheturtle.swf",
        "gameType": "flash",
        "img": "sttt.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Truckloader4",
        "path": "truckloader4.swf",
        "gameType": "flash",
        "img": "tl.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ultimateflashsonic",
        "path": "ultimateflashsonic.swf",
        "gameType": "flash",
        "img": "ufs.webp",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ultimatetactics",
        "path": "ultimatetactics.swf",
        "gameType": "flash",
        "img": "ut.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Unrealflash",
        "path": "unrealflash.swf",
        "gameType": "flash",
        "img": "uf3.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Warfare1917",
        "path": "warfare1917.swf",
        "gameType": "flash",
        "img": "w19.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Warfare1944",
        "path": "warfare1944.swf",
        "gameType": "flash",
        "img": "w44.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Warp",
        "path": "warp.swf",
        "gameType": "flash",
        "img": "warp.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Xenos",
        "path": "xenos.swf",
        "gameType": "flash",
        "img": "xen.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Xtremecliffdiving",
        "path": "xtremecliffdiving.swf",
        "gameType": "flash",
        "img": "CDO.WEBP",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Yearofthesnake",
        "path": "yearofthesnake.swf",
        "gameType": "flash",
        "img": "YOS.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Yuriusshouseofspooks",
        "path": "yuriusshouseofspooks.swf",
        "gameType": "flash",
        "img": "hos.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Zombiealienparasites",
        "path": "zombiealienparasites.swf",
        "gameType": "flash",
        "img": "ap.jpg",
    },
    //
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Vex 6",
        "gameType": "html5",
        "path": "vex6",
        "img": "img222.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stuble Guys Online",
        "gameType": "external",
        "path": "https://now.gg/play/kitka-games/7999/stumble-guys/",
        "img": "img214",
    },


    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "FS Mario",
        "gameType": "html5",
        "path": "FullScreenMario/Source/index.htm",
        "img": "fsmar.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Captain Callisto",
        "gameType": "html5",
        "path": "captaincallisto/",
        "img": "img76.jpg",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fire Boy & Water Girl",
        "gameType": "html5",
        "path": "fbwg/",
        "img": "img12.png",
    },
    //
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Achilles",
        "img": "achiles.png",
        "path": "Achilles.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Age of War",
        "img": "ageofwar.jpg",
        "path": "Age-of-War.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Avalanche",
        "img": "avalanche.jpg",
        "path": "Avalanche.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Avatar Arena",
        "img": "AA.jpg",
        "path": "AvatarArena.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Achievement Unlocked",
        "img": "achievement-unlocked-3.jpg",
        "path": "achievement-unlocked.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": "isTrending",
        "name": "Alien Hominid",
        "img": "ah.png",
        "path": "alien-hominid.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Amberial",
        "img": "amb.webp",
        "path": "amberial.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Animal Hunter",
        "img": "ah.jpg",
        "path": "animal_hunter.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ant Buster",
        "img": "ab.png",
        "path": "antbuster.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Apple Shooter",
        "img": "apple-shooter.jpg",
        "path": "apple-shooter-2021.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Arkanoid",
        "img": "amx.avif",
        "path": "arkanoid.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Axis Football League",
        "img": "afl.jpg",
        "path": "axisfootballleague.swf",
        "gameType": "flash",

    },
    /*{
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "13 Days in Hell",
        "img": "dih.jpg",
        "path": "13-days-in-hell.swf",
        "gameType": "flash",

    },*/

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "3D Car Driver",
        "img": "3dcar.png",
        "path": "3D-Car-Driver.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "3Foot Ninja",
        "img": "3foot.jpg",
        "path": "3_foot_ninja.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "50 states",
        "img": "usa.png",
        "path": "50states.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "3on3 Hockey",
        "img": "3v3hockey.png",
        "path": "3_on_3_hockey.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "8Ball Pool",
        "img": "8bp.png",
        "path": "8BallPool.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloons",
        "img": "mb.avif",
        "path": "Bloons.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Falldown",
        "img": "fall.png",
        "path": "Falldown.swf",
        "gameType": "flash",

    },


    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Acid Bunny 2",
        "img": "acid-bunny-2.jpg",
        "path": "acid-bunny-2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Acid Bunny",
        "img": "ab1.jpg",
        "path": "acid-bunny.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Ball Breaker",
        "img": "bb.png",
        "path": "ball-breaker.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bejeweled",
        "img": "bjc.png",
        "path": "bejeweled-unblocked.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bike Mania",
        "img": "bm.png",
        "path": "bikemania.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bike Mania 2",
        "img": "bm2.jpg",
        "path": "bikemania2.swf",
        "gameType": "flash",

    },


    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Blobink 2",
        "img": "bloooo.png",
        "path": "blobink2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloons Tower Defense 2",
        "img": "bdd.webp",
        "path": "bloons_tower_defense_2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bubble Trouble",
        "img": "btc.png",
        "path": "bubbletrouble.swf",
        "gameType": "flash",

    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Falldown 2",
        "img": "f2.jpg",
        "path": "falldown2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Gun Mayhem 2",
        "img": "gm2.jpg",
        "path": "gun-mayhem-2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Gun Mayhem",
        "img": "gm1.jpg",
        "path": "gun-mayhem.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "The Impossible Quiz 2",
        "img": "tic2.avif",
        "path": "impossiblequiz2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Last Stand Union City",
        "img": "lauc.png",
        "path": "the-last-stand-union-city.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Worlds Hardest Game 2",
        "img": "whg2.png",
        "path": "worldshardestgame2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Zombocalypse",
        "img": "zom.jpg",
        "path": "zombocalypse.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Canyon Defense",
        "img": "cdfd.jpg",
        "path": "Canyon Defense.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Copter",
        "img": "copt.png",
        "path": "Copter.swf",
        "gameType": "flash",

    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Donkey Kong",
        "img": "donkey.webp",
        "path": "DonkeyKong.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Draw Play",
        "img": "draw-play-game.webp",
        "path": "Drawplay.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Draw Play 2",
        "img": "dp2.jpg",
        "path": "DrawPlay2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "The Beard",
        "img": "tb.jpg",
        "path": "castlecrashingthebeard.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Choose Your Weapon 2",
        "img": "cyw2.png",
        "path": "chooseyourweapon2_LB.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Clear Vision",
        "img": "cv.png",
        "path": "clear-vision.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Clear Vision Elite",
        "img": "cve.jpg",
        "path": "clearvisionelite.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Crazy Taxi",
        "img": "ct1.webp",
        "path": "crazy-taxi.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Cursor 10",
        "img": "c10.jpg",
        "path": "cursor10.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Dad N' Me",
        "img": "dnm.jpg",
        "path": "dadnme.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Dance of the Robots",
        "img": "rdp.png",
        "path": "danceoftherobots.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Dental Adventure",
        "img": "da.webp",
        "path": "dental-adventure.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Duck Hunt",
        "img": "dh.png",
        "path": "duck-hunt.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "DX Hockey",
        "img": "dxh.jpg",
        "path": "dxhockey.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bloxors",
        "img": "blox.jpg",
        "path": "Bloxors.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bullet Bill 2",
        "img": "bb2.png",
        "path": "Bullet Bill 2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bullet Bill 3",
        "img": "bb3.webp",
        "path": "Bullet Bill 3.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bomb a Bomb",
        "img": "bab.png",
        "path": "bombabomb.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bow Man",
        "img": "bom.webp",
        "path": "bowman.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bow Man 2",
        "img": "bom2.jpg",
        "path": "bowman2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bowmaster",
        "img": "bowm.png",
        "path": "bowmaster.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bowmaster Prelude",
        "img": "bowmp.jpg",
        "path": "bowmasterprelude.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bubble Shooter",
        "img": "bs.png",
        "path": "bubble shooter.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bubble Tanks 2",
        "img": "bt.jpg",
        "path": "bubble_tanks_2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bullet Bill",
        "img": "bbb.jpg",
        "path": "bullet-bill.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Burger Tycoon",
        "img": "bty.png",
        "path": "burgertycoon.swf",
        "gameType": "flash",

    },
 {
        "dateAdded" : "2023-05-07",
        "description": "Play as a pirate on various themed islands! Open chests and collect doubloons to get bigger. Kill other players to collect their coins, get bigger again! Use your special dash ability to boost your speed and overcome your opponents.",
        "isTrending": false,
        "name": "Yohoho.io",
        "path": "https://yohoho-io.top/",
        "img": "yoho.jpeg",
        "gameType": "external",
        },

    {
    "dateAdded" : "2023-05-07",
    "description": "Prison Life is a Cops vs Robbers game where prisoners must attempt to escape the prison, and police must attempt to keep the prisoners in. Once you escape, you can explore the map and do robberies to earn more money.",
    "isTrending": false,
    "name": "Prison Life",
    "path": "https://prisonlifegame.com",
    "img": "pl.png",
    "gameType": "external",
    },
    {
        "dateAdded" : "2023-05-07",
        "description": "Tabs Totally. Accurate. Battle. Simulator",
        "isTrending": false, 
        "name": "T.A.B.S",
        "path": "https://landfall.se/totally-accurate-battle-simulator",
        "img": "tabs.jpg",
        "gameType": "external",
        },
    {
    "dateAdded" : "2023-05-07",
    "description": "Doors! is an endless, 2D, .io game in which you must survive.",
    "isTrending": false,
    "name": "Doors!",
    "path": "https://www.modd.io/play/DoorsB9K",
    "img": "door.png",
    "gameType": "external",
    },
    {
        "dateAdded" : "2023-05-07",
        "description": "Safe Haven is a survival game where you need to survive multiple days with provided resources around the map and get in your escape vehicle to reach your safe haven.",
        "isTrending": false,
        "name": "Safe Haven",
        "path": "https://www.modd.io/play/SafeHaven",
        "img": "safeh.png",
        "gameType": "external",
    },
    {
        "dateAdded" : "2023-05-07",
        "description": "Lucky Block Battles is a competitive PvP and round-based game in which you must break open Lucky Blocks to get better and better weapons to kill all your opponents.",
        "isTrending": false,
        "name": "Lucky BlockBattles",
        "path": "https://www.modd.io/play/Luckyblocks",
        "img": "luckblockb.png",
        "gameType": "external",
        },
    {
        "dateAdded" : "2023-05-07",
        "description": "Mysterious Manor is a murder mystery style game where you need to kill everyone as the murder or survive as an innocent or detective.",
        "isTrending": false,
        "name": "Mysterious Manor",
        "path": "https://www.modd.io/play/MysteriousManor/",
        "img": "mysman.png",
        "gameType": "external",
        },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Boxhead 2",
        "img": "bh2.png",
        "path": "dagobah_boxhead_2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Electric Man",
        "img": "em.png",
        "path": "ElectricMan.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Excite Bike",
        "img": "eb.jpg",
        "path": "ExciteBike.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Feudalism 2",
        "img": "f2.png",
        "path": "Feudalism2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Flood Runner 3",
        "img": "fr3.png",
        "path": "Flood_Runner3.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Flood Runner 4",
        "img": "fr4.jpg",
        "path": "Flood_Runner_4.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Flood Runner 2",
        "img": "fr2.png",
        "path": "The_Flood_Runner_2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Effing Worms",
        "img": "eiw.png",
        "path": "effingworms.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Fat Ninja",
        "img": "fn.jpg",
        "path": "fat-ninja.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Feudalism",
        "img": "feud.png",
        "path": "feudalism.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Flashcraft",
        "img": "fs.png",
        "path": "flashcraft.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Free Rider 2",
        "img": "frg2.jpg",
        "path": "freerider2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Frogger",
        "img": "frg.png",
        "path": "frogger.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Grow RPG",
        "img": "grpg.webp",
        "path": "growrpg.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Gods Playing Field",
        "img": "gpf.jpg",
        "path": "Gods Playing Field.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Grid Lock",
        "img": "gr.avif",
        "path": "Gridlock.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo1",
        "img": "hof.jpg",
        "path": "Hobo.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Indestructo Tank",
        "img": "it.jpg",
        "path": "IndestructoTank.swf",
        "gameType": "flash",

    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mario Revived",
        "img": "mrm.jpg",
        "path": "Mariorevived.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Max Dirt Bike 1",
        "img": "mdb.jpg",
        "path": "MaxDirtBike1.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Monopoly",
        "img": "monopoly.png",
        "path": "Monopoly.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "N (retro game)",
        "img": "na.png",
        "path": "N.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Parachute",
        "img": "na.png",
        "path": "Parachute.swf",
        "gameType": "flash",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Prince of War",
        "img": "na.png",
        "path": "PrinceofWar.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Prince of War 2",
        "img": "na.png",
        "path": "PrinceofWar2.swf",
        "gameType": "flash",

    },
    
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Shock 1",
        "img": "na.png",
        "path": "SHOCK1.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stick War",
        "img": "na.png",
        "path": "Stick War.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Storm the House 2",
        "img": "na.png",
        "path": "StormtheHouse2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tactical Assassin",
        "img": "na.png",
        "path": "TacticalAssassin.swf",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tactical Assassin 2",
        "img": "na.png",
        "path": "TacticalAssassin2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "The Unfair Platformer",
        "img": "na.png",
        "path": "The Unfair Platformer.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Toss the Turtle",
        "img": "na.png",
        "path": "Toss The Turtle.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Zelda",
        "img": "na.png",
        "path": "Zelda.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Zoeken",
        "img": "na.png",
        "path": "Zoeken.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Armor RPG Experiment",
        "img": "na.png",
        "path": "armorrpg_mod.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Galaga",
        "img": "na.png",
        "path": "galaga.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Geo Land RPG",
        "img": "na.png",
        "path": "geolandrpg.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Grid 16",
        "img": "na.png",
        "path": "grid16.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Grow Island",
        "img": "na.png",
        "path": "growisland.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Gun Blood",
        "img": "na.png",
        "path": "gunblood.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hell Cops",
        "img": "na.png",
        "path": "hell-cops.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hexxagon",
        "img": "hexagon.png",
        "path": "hexxagon.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo 4: Total War",
        "img": "na.png",
        "path": "hobo-4-total-war-749179f9.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo 5: Space Brawls",
        "img": "na.png",
        "path": "hobo-5-space-brawls--11410f2f2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo 6: Hell",
        "img": "na.png",
        "path": "hobo-6-hell-136403467.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo 7: Heaven",
        "img": "na.png",
        "path": "hobo-7-heaven-14404_6.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo 3: Wanted",
        "img": "na.png",
        "path": "hobo3-wanted-5673395b.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hobo Prison Brawl",
        "img": "na.png",
        "path": "hoboprisonbrawl.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Hot Corn",
        "img": "na.png",
        "path": "hotcorn.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Impossible Quiz Book",
        "img": "na.png",
        "path": "impossiblequizbook.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "Bigfoot's Favorite Game",
        "isTrending": "isTrending",
        "name": "Indestructo Tank 2",
        "img": "indtank.webp",
        "path": "indestructotank2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Interactive Buddy 2",
        "img": "na.png",
        "path": "interactivebuddy2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Internet RPG",
        "img": "na.png",
        "path": "internet-rpg.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Jump",
        "img": "na.png",
        "path": "jump.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Kung Fu Remix 2",
        "img": "na.png",
        "path": "kungfuremix2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Line Game Orange",
        "img": "na.png",
        "path": "linegameorange.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mario Combat",
        "img": "na.png",
        "path": "mariocombat.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Mini Putt",
        "img": "na.png",
        "path": "miniputt.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Only Level",
        "img": "na.png",
        "path": "onlylevel.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "PacXon",
        "img": "na.png",
        "path": "pacxon.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pandemic 1",
        "img": "na.png",
        "path": "pandemic-1.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pandemic 2",
        "img": "na.png",
        "path": "pandemic2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Parking Mania",
        "img": "na.png",
        "path": "parkingmania.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Raft Wars 2 ALT",
        "img": "raftwars2.png",
        "path": "raft-wars-2_mochi_secure.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Real Estate Tycoon",
        "img": "na.png",
        "path": "real-estate-tycoon-1350817f.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Red Shift",
        "img": "na.png",
        "path": "redshift.swf ",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Robot Unicorn Attack",
        "img": "na.png",
        "path": "robotunicornattack.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads 1",
        "img": "na.png",
        "path": "sift-heads-1.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads 2",
        "img": "na.png",
        "path": "sift-heads-2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads 3",
        "img": "na.png",
        "path": "sift-heads-3.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads 4",
        "img": "na.png",
        "path": "sift-heads-4.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads 5",
        "img": "na.png",
        "path": "sift-heads-5.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sift Heads World",
        "img": "na.png",
        "path": "sift_heads_world.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sim Web 2.0 Company",
        "img": "na.png",
        "path": "simweb2.0company.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Skull Kid",
        "img": "na.png",
        "path": "skull_kid.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sky Wire",
        "img": "na.png",
        "path": "skywire.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Spank the Monkey",
        "img": "na.png",
        "path": "spankthemonkey.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Spider Man",
        "img": "na.png",
        "path": "spider-man.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Sprinter",
        "img": "na.png",
        "path": "sprinter",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stick RPG",
        "img": "na.png",
        "path": "stickrpg.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Submachine 1",
        "img": "na.png",
        "path": "submachine_1.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Submachine 2",
        "img": "na.png",
        "path": "submachine_2.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Submachine 3",
        "img": "na.png",
        "path": "submachine_3.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Super Smash Flash",
        "img": "na.png",
        "path": "super_smash_flash.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Super Mario 63",
        "img": "sm63.png",
        "path": "supermario63.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Treasure Seas ",
        "img": "na.png",
        "path": "treasureseas.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tron",
        "img": "na.png",
        "path": "tron.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Tsunami Fighter",
        "img": "na.png",
        "path": "tsunamifighter.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Use Boxmen",
        "img": "na.png",
        "path": "use-boxmen.swf",
        "gameType": "flash",

    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Kitten Cannon",
        "img": "na.png",
        "path": "yetiGamesKitten Cannon.swf",
        "gameType": "flash",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Drift Hunters",
        "gameType": "html5",
        "path": "drift-hunters/",
        "img": "img53.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "TU-95",
        "img": "na.png",
        "path": "TU-95.swf",
        "gameType": "flash",

    },
    //end gfiles
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Roblox",
        "gameType": "external",
        "path": "https://now.gg/play/roblox-corporation/5349/roblox",
        "img": "img59.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Krunker.io",
        "gameType": "external",
        "path": "https://krunker.io/",
        "img": "img65.png",
    },
//cd /frontend
//npm start
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Temple Run 2",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/html5-games/games/templerun2/",
        "img": "img58.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Football Master",
        "gameType": "external",
        "path": "https://football-master-1.bigfootgaming99.repl.co/",
        "img": "soccer-master.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Burrito Bison",
        "gameType": "external",
        "path": "https://grandcanyonshuttles.com/uploads/5/5/6/7/5567194/custom_themes/607721921917323670/burrito-bison-ll.html",

        "img": "img4.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "SubSurfers Huston",
        "gameType": "external",
        "path": "https://more-school.bigfootgaming99.repl.co/",
        "img": "HoustonSS",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Champion island",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/html5-games/games/google-kitsune/",

        "img": "img3.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "LZ: Link to the Past",
        "gameType": "external",
        "path": "https://t-rexrunner.github.io/GBA-Games/launcher.html#zelda_past",

        "img": "img5.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "The Flash",
        "gameType": "external",
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/7334676/g2/index.html",
        "img": "flash",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Trackmania",
        "gameType": "external",
        "path": "https://v6p9d9t4.ssl.hwcdn.net/html/7429806/t1/index.html",
        "img": "drive.png",
    },


    {
        "isTrending": false,
        "name": "LZ: Minish Cap",
        "gameType": "external",
        "path": "https://t-rexrunner.github.io/GBA-Games/launcher.html#zelda_minish",
        "img": "img6.jpg",
        "dateAdded" : "2023-01-07",
        "description": "LZ: Minish Cap is a classic action-adventure game that was released for the Game Boy Advance in 2004. In the game, you play as Link, who must rescue Princess Zelda and the kingdom of Hyrule from the evil wizard Vaati."
    },
    {
        "isTrending": false,
        "name": "Mario Party",
        "gameType": "external",
        "path": "https://t-rexrunner.github.io/GBA-Games/launcher.html#marioparty",
        "img": "img7.jpg",
        "dateAdded" : "2023-01-07",
        "description": "Mario Party is a party game featuring characters from the Mario franchise. The game is played on a virtual board game and features various mini-games that players must compete in to win coins and stars."
    },
    {
        "isTrending": false,
        "name": "Mortal Kombat",
        "gameType": "external",
        "path": "https://t-rexrunner.github.io/GBA-Games/launcher.html#mortal_kombat",
        "img": "img8.jpg",
        "dateAdded" : "2023-01-07",
        "description": "Mortal Kombat is a classic fighting game that was first released in 1992. The game features a roster of characters, each with their own unique moves and abilities, who must fight each other to the death."
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Kuru Panda",
        "gameType": "external",
        "path": "https://www.panda2.io/content/games/kurupanda/",

        "img": "img9.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Indian Truck Sim",
        "img": "indiantruck.png",
        "gameType": "external",
        "path": "https://too-advanced-for-society.gq/tam-indian-truck-simulator-3d/",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Space Company",
        "gameType": "external",
        "path": "https://spacecompany.ga/",

        "img": "img11.jpg",
    },
    /*
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Rolling Sky",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/webgl-rollingsky/",
        
        "img" : "img14.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Tank Trouble",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/tanktrouble/",
        
        "img" : "img16.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Vex 3",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/vex3/",
        
        "img" : "img17.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Vex 4",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/vex4/",
        
        "img" : "img18.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Zombotron",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/zombotron/",
        
        "img" : "img19.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending":false,
        "name": "Zombotron 2",
         "gameType": "external",
"path": "https://rga-bigfoot9999.vercel.app/src/zombotron-2-time-machine/",
        
        "img" : "img20.png",
    },
    */
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Insanity",
        "gameType": "external",
        "path": "https://insanity.netlify.app/",

        "img": "img21.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Insanity 2",
        "gameType": "external",
        "path": "https://insanity2.netlify.app/",

        "img": "img22.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Bottle Flip 3d",
        "gameType": "external",
        "path": "https://grandcanyonshuttles.com/uploads/5/0/5/6/5056555/custom_themes/664718197367015386/bottle-flip.html",

        "img": "img23.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "GTA",
        "gameType": "external",
        "path": "https://grandcanyonshuttles.com/uploads/5/5/6/7/5567194/custom_themes/850704625817689097/gas2.html",

        "img": "img24.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "CurveBall",
        "gameType": "external",
        "path": "https://www2.engineering.com/content/g13/curve23.html",

        "img": "img25.jpg",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Headless.gg",
        "gameType": "external",
        "path": "https://headless.gg/",

        "img": "img27.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Amoug Us",
        "gameType": "external",
        "path": "https://school.bigfootgaming99.repl.co/",
        "img": "img223",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "OvO",
        "gameType": "html5",
        "path": "ovo/",
        "img": "img30.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Slither.io",
        "gameType": "external",
        "path": "https://www.julgames.com/games/slither-snakes/",
        "img": "img31.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Woriar",
        "gameType": "external",
        "path": "https://www.panda2.io/content/games/woriar/",
        "img": "img38.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "lets Surf!",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/html5-games/games/edge-surf/",
        "img": "img52.jpg",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Tower Building",
        "gameType": "external",
        "path": "https://fracital.org/g/tower-building/",

        "img": "img60.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Lol Beans",
        "gameType": "external",
        "path": "https://lolbeans.io/",

        "img": "img113",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Piano Master",
        "gameType": "external",
        "path": "https://play.gamemonetize.co/q0mrnyr9ucvgdqgooq9cq0y7fntuwzjj/",
        "img": "img61.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "server 2",
        "isTrending": false,
        "name": "Bob The Robber",
        "gameType": "external",
        "path": "https://play.gamemonetize.co/axjqhwihwo843nmu8hb30fqil40alqtu/",
        "img": "img63",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Driving in the City",
        "gameType": "external",
        "path": "https://play.gamemonetize.co/5e7ehppi0cufmsfktf2yy5sb6u3d1nwf/",
        "img": "img67.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Blumji Rocket",
        "gameType": "external",
        "path": "https://play.gamemonetize.co/x9szn9qmh0ygsmct93p7mny35850lpx1/",
        "img": "img213",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "BasketBall Stars",
        "gameType": "external",
        "path": "https://3kh0.github.io/projects/basketball-stars/index.html",
        "img": "img215",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Pokemon Silver",
        "gameType": "external",
        "path": "https://static.arcadespot.com/retroemulator.php?system=gbc&game=2016/07/pokemon-silver.zip",
        "img": "img217",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Diep.io",
        "gameType": "external",
        "path": "https://diep.io/",
        "img": "img218",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Subway Surfers",
        "gameType": "external",
        "path": "https://havana.application08.repl.co/",
        "img": "img221",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Cookie Clicker",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/cc/",
        "img": "img29.png",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Vivalda",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/html5-games/games/vivaldia/",
        "img": "img84.png",
    },
    
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "1v1.lol",
        "gameType": "external",
        "path": "https://nowcollege.com/uploads/5/5/6/7/5567194/custom_themes/315300867982782623/1v1_lol.html",
        "img": "img46.jpg",
    },
  
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Shell Shockers",
        "gameType": "external",
        "path": "https://tam-shellshockers.arfotoarte.com/",
        "img": "img48.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Blockpost.io",
        "gameType": "external",
        "path": "https://ikatchelo.github.io/blockpost/",
        "img": "img45.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Rooftop Snipers",
        "gameType": "external",
        "path": "https://bigfoot9999.github.io/3kh0.github.io/projects/rooftop-snipers/",
        "img": "img49.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Pixel Combat 2",
        "gameType": "external",
        "path": "https://grandcanyonshuttles.com/uploads/5/0/5/6/5056555/custom_themes/607463927942590340/pixel-combat2.html",
        "img": "img50.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Modern Blocky Paint",
        "gameType": "external",
        "path": "https://zayaruzostreetorgan.com/uploads/5/5/6/7/5567194/custom_themes/593675271171586975/modern_blocky_paint_.html",
        "img": "img51.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Block Combat Swat",
        "gameType": "external",
        "path": "https://html5.gamemonetize.co/z4ajge69b989gm67dq59mkgv20ed6zg6/",
        "img": "img62",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Mini Royale 2",
        "gameType": "external",
        "path": "https://miniroyale.clambam10.repl.co/",
        "img": "img66",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "MooMoo.io",
        "gameType": "external",
        "path": "https://moomoo.io/",
        "img": "img219",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,

        "name": "Fortnite",
        "gameType": "external",
        "path": "https://www.nvidia.com/en-us/geforce-now/fortnite-mobile/  ",
        "img": "img220",
    },

    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Doomz.io",
        "gameType": "external",
        "path": "https://kardna-v2.herokuapp.com/KardnaV2/hvtrs8%2F-dmooz%2Cim%2F",
        "img": "doomz-io.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Stickman Hook",
        "gameType": "external",
        "path": "https://magictricks.milkysdad.repl.co/gamefiles/stickmanhook/",
        "img": "stickman.jpg",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "JustFall.LOL",
        "gameType": "external",
        "path": "https://stars-delta.vercel.app/play/justfall-lol/",
        "img": "justfall.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Basket Bros",
        "gameType": "external",
        "path": "https://nbadebate.com",
        "img": "basket-bros.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Granny",
        "gameType": "external",
        "path": "https://zayaruzostreetorgan.com/uploads/5/5/6/7/5567194/custom_themes/253523348422157506/1granny.html",
        "img": "granny.png",

    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Gold Digger",
        "gameType": "external",
        "path": "https://golddigger.frvr.com/",
        "img": "gold.png",
    },
    {
       "dateAdded" : "2023-01-07",
        "description": "",
        "isTrending": false,
        "name": "Minecraft Trial",
        "gameType": "external",
        "path": "https://now.gg/play/mojang/2534/minecraft-trial",
        "img": "grass.png",
    },
    
];
if (gamedata.gameType === "external") {
    var encodedURL = btoa(gamedata.path);
    gamedata.path = encodedURL;
}


export default gamedata;

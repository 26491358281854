import GameSlider from "./GameSlider";
import Back from '../icons/Back.png';
import SlopeAd from "./SlopeAd";
import MinecraftText from "./MinecraftText";
import BlockpostAd from "./BlockpostAd";
const LandingPageHome = () =>{
    return (
        <>
        
      <div className="landing-section2">
      <MinecraftText />
        <div className="text-container-main-page">
        <h1 className="bgs-title-main-page">Bigfoot's Game Shack</h1>
        <p className="bgs-title-main-page-p">Play over 500+ games made for ChromeBook gaming.</p>
        <a href="#topg"><div className="button-main-page-go-down"><img className="arrow-main-page"src={Back}/></div></a>
        </div>
      <video className="video-mainpage" id="bgvid" playsInline autoPlay muted loop>
<source src="/assets/images/video-bg-bgs-main.mp4" type="video/mp4"/></video>
      </div>
      
      <div id="topg"className="landing-section3">
      <a href="/play"><div className="button-playnow"><h2>PLAY NOW</h2></div></a>
      <GameSlider />
        </div>


      <div className="landing-section3">
        
      <div style={{
        margin: 'auto',
        marginBottom: '100px',

      }} className="landing-section2-cb">
        
        <h2 style={{
          textAlign: 'center',
          marginTop: '100px',
          marginBottom: '100px',
        }} className="top-games-title-cb">Built for Chromebooks.</h2>
        <p2 className="top-games-des-cb">   
        Designed specifically for Chromebook gaming.
        <br/>
        <br/>
        less lag, more fun.
        <br/>
        <br/>
        Secure, fast, and reliable.
        <br/>
        <br/>
        Five Million Visitors and Counting.
        
                <br/>
        <br/>
        Powered by Ruffle Emulator and React.
        <br/>
        <br/>
        Since December 2021.
        </p2>
          </div>
        <SlopeAd />
        <BlockpostAd />

      </div>

      </>
    );
  };
  
  
  export default LandingPageHome;
import articledata from './articledata';
import '../App.css';
import {Link} from 'react-router-dom'
import SlopeAd from '../components/SlopeAd';

const ArticlesListPage = () => {
    return (
        <>         

        <div className="container-articles">
        <img src='/assets/images/builtforChromebookgamerstransparent.png' alt="Built for Chromebook Gamers" className="bfcgbigfoottext"></img>       
            {articledata.map(articledata => (
            <Link key={articledata.name}className="article-list-item"to={articledata.name}>
                <div class="article-conent-list">
                    <h3>{articledata.title}</h3>
                    <p>{articledata.content[0].substring(0, 150)}...</p>
                    <p3 >{articledata.date}</p3>
                </div></Link>
            ))}
            </div><SlopeAd />
        </>

        )
}
export default ArticlesListPage;
import { useState } from "react";
import { Link } from "react-router-dom";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import gamedata from "../pages/gamedata";
const FilterGames = () => {
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);
  const currentDate = new Date();
  const sixtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
  const dateString = sixtyDaysAgo.toISOString().slice(0, 10);
  console.log(dateString);
  const newdateString = "/play/?date=" + dateString;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const searchParams = new URLSearchParams(location.search);

  let filteredGames = gamedata;

  const category = searchParams.get('type');
  const category1 = searchParams.get('tag');
  const category2 = searchParams.get('date');
  if (category2) {
    filteredGames = filteredGames.filter((game) => game.dateAdded >= category2);
  }
  if (category) {
    filteredGames = filteredGames.filter((game) => game.gameType === category);
  }
  if (category1) {
    filteredGames = filteredGames.filter((game) => game.isTrending === category1);
  }
  return (
    <div className="filter-games-container" >
      <div className="filter-games-header" onClick={toggleDropdown}>
        <BsFillCaretDownFill className="filter-icon" />
        <span>
          {category === "external" &&
          "EXTERNAL GAMES"+ ` (${filteredGames.length})`}
          {category === "html5"  &&
          "HTML5 GAMES"+ ` (${filteredGames.length})`}
          {category === "flash"  &&
          "FLASH GAMES"+ ` (${filteredGames.length})`}
            {category === "waflash"  &&
          "WAFLASH GAMES"+ ` (${filteredGames.length})`}
           {category1 === "isTrending"  &&
          "TRENDING GAMES"+ ` (${filteredGames.length})`}
            {category2 &&
          "NEW GAMES"+ ` (${filteredGames.length})`}

{category === null && category1 === null && category2 === null  &&
          `ALL GAMES`+ ` (${gamedata.length})`}
          </span>
      </div>
      {showDropdown && (
        <div className="filter-games-dropdown">
          <Link to="/play/">All</Link>
          <Link to="/play/?type=external">External</Link>
          <Link to="/play/?type=html5">HTML5</Link>
          <Link to="/play/?type=flash">Flash</Link>
          <Link to="/play/?type=waflash">WAFlash</Link>

          <Link to="/play/?tag=isTrending">Trending</Link>
          <Link to={newdateString}>New</Link>
        </div>
      )}
    </div>
  );
};

export default FilterGames;

import { Link } from "react-router-dom";
const NotFound = () => (
    <>
<h1 className="center404">This page was not found in our database</h1><br/>
<Link to="/"><h1 className="center404links">Go back home</h1></Link>
<br/>
<Link to="/play"><h1 className="center404links">or play games</h1></Link>
</>
    );

export default NotFound;
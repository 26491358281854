import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import gamedata from '../pages/gamedata.js';
import { Link } from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import Trending from '../Trending.png'
import SearchBar from './SearchBar.js';
const SearchGames = () => {
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');
    if (query) {
      setSearchTerm(query);
    }
  }, [location.search]);

  // ...

useEffect(() => {
  const filteredData = gamedata.filter((game) =>
    game.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort by isTrending first, then by name
  const sortedData = filteredData.sort((a, b) => {
    // Convert the isTrending values to booleans for comparison
    const isTrendingA = a.isTrending == "isTrending";
    const isTrendingB = b.isTrending == "isTrending";

    // If one item is trending and the other is not, prioritize the trending one
    if (isTrendingA && !isTrendingB) {
      return -1;
    } else if (!isTrendingA && isTrendingB) {
      return 1;
    }
    // If both are trending or both are not trending, sort by name
    return a.name.localeCompare(b.name);
  });

  setSortedData(sortedData);
}, [searchTerm]);

// ...

  ///
  if (sortedData.length === 0) {
    return (  
      <>
    <h1 style={{marginLeft : '30px',}}>We couldn't find anything called <mark>"{searchTerm}"</mark></h1>
    <br/>
    <SearchBar />
    <br/><br/><br/><br/><br/><br/><br/>
    <div style={{marginBottom : '60vh',}}></div>
    
    </>
    
)
  }
  return (
    <>
    <div>
            <SearchBar />

      <h2 style={{textAlign:'center',}}>Search Results for "{searchTerm}"</h2>

      {sortedData.map((game) => (
        <div className='searchContainer'>
        <Link to={"/loading?url="+base64_encode(`${game.path}`) + `?imgId=${game.img}&gameName=${game.name}&isTrending=${game.isTrending}&type=${game.gameType}`}>
          
        <div className='search-results-div'key={game.id}>
        
        <div className='search-results-div-contents'key={game.id}>
          <h3 className='h3search'>{game.name}</h3>
          <img alt={game.name} class="search-results-div-img"src={"../assets/images/"+game.img}/>
          { game.isTrending ?
                                 <img key={game.name} alt="Trending Icon"className="trending-search-card" src={Trending} /> : null
                                }
            {game.description.length >= 1 ?
                       <div className='glow'><p5 className="glow search-description">"{game.description.substring(0, 150)}..."</p5></div>: null 
        }
        
          </div>
          <img style={{backgroundImage: `url("../assets/images/${game.img}")`}}className='search-results-div-blur' alt={game.name}/>
        </div>
        </Link>
        </div>
      ))}
      <p2 className="center">End of results</p2>

    </div></>
      );
};
export default SearchGames;
import './App.css';
import { Link } from 'react-router-dom';
/*<div className="footer-logo">
<img src="/assets/images/bigfoot.png" alt="Logo" />

            </div>*/
const Footer = () => {
    return (
        <footer className="dark-mode">
          <div className="footer-container">
            <div className="footer-links">
<img src='/assets/images/builtforChromebookgamerstransparent.png' alt="Built for Chromebook Gamers" className="bfcgbigfoottext-footer"></img>       

             <div className='span4'>
                <h3>Links</h3>
                <Link className="footerlinks" to='/'>Home</Link>
                <br/>
                <Link className="footerlinks" to='/play'>Games</Link>
                <br/>
                <Link className="footerlinks" to='/articles'>Articles</Link>
                <br/>

             </div>
             <div className='span4'>
                <h3>Socials</h3>
                <a className="footerlinks" href='https://youtube.com/@bigfoots-game-shack'>YouTube</a>
                <br/>
                <a className="footerlinks" href='https://www.tiktok.com/@bigfoot_game_shack?lang=en'>TikTok</a>
                <br/>
                <a className="footerlinks" href='https://github.com/Bigfoot9999/'>Github</a>
                <br/>
                <a className="footerlinks" href='https://twitter.com/Bigfoot99991'>Twitter</a>
                <br/>
             </div>
             <div className='span4'>
                <h3>Contact</h3>
                <a className="footerlinks" href='mailto:bigfoot@bigfootshack.games'>EMAIL</a>
                <br/>
                <a className="footerlinks" href='https://discord.com/invite/JYQ4n8DBKM'>DISCORD</a>
                <br/>
             </div>
             
            </div>
          </div>
        </footer>
      );
}

export default Footer;